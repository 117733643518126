import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { useNimike } from '@/methods/nimikkeetComposables';
import { useNimikeMuokkausComponent } from './nimikeMuokkaus';
import { ref } from 'vue';
import { HuonekorttiNimikeResponse } from '@/api/models/huonekortti-nimike-response';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComNimikeMuokkausNimike',
  setup(__props) {

const huonekorttiNimike = ref<HuonekorttiNimikeResponse>({} as HuonekorttiNimikeResponse)
const { nimikeId, overwriteNimikkeet } = useNimikeMuokkausComponent()
const { nimike } = useNimike(nimikeId)


return (_ctx: any,_cache: any) => {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    class: "el-card-blank",
    shadow: "never"
  }, {
    header: _withCtx(() => _cache[4] || (_cache[4] = [
      _createTextVNode(" Muokkaa kaikkia alla olevia nimikkeitä ")
    ])),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        inline: true,
        "label-position": "top"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, {
            lg: 5,
            span: 24
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "Nimike",
                prop: "nimike"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(nimike)?.nimi), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            lg: 5,
            span: 24
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { label: "Ominaisuudet" }, {
                default: _withCtx(() => [
                  (_unref(nimike))
                    ? (_openBlock(), _createBlock(_component_el_select, {
                        key: 0,
                        multiple: "",
                        modelValue: huonekorttiNimike.value.ominaisuudet,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((huonekorttiNimike.value.ominaisuudet) = $event)),
                        placeholder: "Valitse ominaisuudet",
                        style: {"width":"100%"},
                        size: "small",
                        "value-key": "id"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(nimike).ominaisuudet, (ominaisuus, index) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: index,
                              label: ominaisuus.nimi,
                              value: ominaisuus
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            lg: 3,
            span: 24
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "Kpl",
                prop: "maara"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input_number, {
                    min: 0,
                    size: "small",
                    modelValue: huonekorttiNimike.value.maara,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((huonekorttiNimike.value.maara) = $event)),
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            lg: 7,
            span: 24
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { label: "Täsmennys" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: huonekorttiNimike.value.tasmennys,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((huonekorttiNimike.value.tasmennys) = $event)),
                    type: "textarea",
                    autosize: "",
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            lg: 4,
            span: 24
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { label: " " }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "success",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(overwriteNimikkeet)(huonekorttiNimike.value))),
                    size: "small"
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("Ylikirjoita")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})