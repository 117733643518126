import { Ref, computed, ref, watch } from "vue"
import http from "@/http-common"
import { useMutation, useQuery } from "@tanstack/vue-query"
import { NimikeResponse } from "@/api/models/nimike-response"
import { KategoriaResponse } from "@/api/models/kategoria-response"
import { CascaderOption } from "element-plus/es/components/cascader-panel/src/node"
import { CascaderNode } from "element-plus"
import { HuonekorttiNimikeWithHuonekorttiResponse } from "@/api/models/huonekortti-nimike-with-huonekortti-response"
import { HuonekorttiNimikeSearchRequest } from "@/api/models/huonekortti-nimike-search-request"
import { AxiosResponseHeaders } from "axios"

const nimikkeetUrl = "/nimikkeet"
const nimikeKategoriatUrl = "/nimikkeet/kategoriat"

export function useNimikkeet() {
    const key = ["/nimikkeet"]
    
    const fetcher = async () : Promise<NimikeResponse[]> => {
        return (await http.get(nimikkeetUrl)).data
    }

    const { data, isLoading: loading, error } = useQuery({queryKey: key, queryFn: fetcher, staleTime: 1000 * 60 * 60 * 24})

    return {
        nimikkeet: data,
        loading,
        error,
    }
}

export function useNimike(id: Ref<number | undefined>) {
    const { nimikkeet, loading, error } = useNimikkeet()

    return {
        nimike: computed(() => nimikkeet.value?.find((nimike) => nimike.id === id.value)),
        loading,
        error
    }
}

export function useNimikeKategoria(nimikkeet?: Ref<boolean>, ei_kaytossa?: Ref<boolean>) {
    const key = computed(() => {
        const params = Object.assign({},
            nimikkeet?.value ? { nimikkeet: nimikkeet.value } : null,
            ei_kaytossa?.value ? { ei_kaytossa: ei_kaytossa.value } : null
        )

        return [nimikeKategoriatUrl, params]
    })
    
    const fetcher = async () : Promise<KategoriaResponse[]> => {
        return (await http.get(nimikeKategoriatUrl, { params: key.value[1] })).data
    }

    const { data, error, isLoading: loading } = useQuery({queryKey: key, queryFn: fetcher, staleTime: 1000 * 60 * 60 * 24})

    return {
        nimikeKategoriat: data,
        error,
        loading
    }
}

export function searchNimikkeet() {
    const nimikkeet = ref<HuonekorttiNimikeWithHuonekorttiResponse[]>([])
    const total = ref(0)

    const search = (params: HuonekorttiNimikeSearchRequest) => {
        mutate(params)
    }

    const fetcher = async (request: HuonekorttiNimikeSearchRequest) => {
        const response = await http.post("huonekortit/nimikkeet/hae", request)

        return {
            data: response.data as HuonekorttiNimikeWithHuonekorttiResponse[],
            headers: response.headers as AxiosResponseHeaders,
        }
    }

    const { data, error, isPending: loading, mutate } = useMutation({
        mutationFn: (request: HuonekorttiNimikeSearchRequest) => fetcher(request),
    })

    watch(data, () => {
        if (data) {
            nimikkeet.value = data.value?.data ?? []
            total.value = data.value?.headers["page-total-count"]
                ? parseInt(data.value?.headers["page-total-count"])
                : 0
        }
    })

    return {
        nimikkeet,
        total,
        error,
        loading,
        search,
    }
}


export function setNodes(kategoriat: KategoriaResponse[], poistetut?: boolean) {
    const nodes = [] as CascaderOption[]

    kategoriat.forEach((k) => {
        const node = {} as CascaderOption
        node.value = k.id
        node.label = k.nimi
        node.disabled = !k.kaytossa
        k.children?.forEach((c) => {
            const child = {} as CascaderOption
            child.value = c.id
            child.label = c.nimi
            child.disabled = !c.kaytossa
            c.nimikkeet?.forEach((n) => {
                const nimike = {} as CascaderOption
                nimike.value = n.id
                nimike.label = n.nimi
                nimike.disabled = !n.kaytossa
                if (!nimike.disabled || (nimike.disabled && poistetut)) {
                    if (child.children == undefined) child.children = []

                    child.children.push(nimike)
                }
            })
            if (!child.disabled || (child.disabled && poistetut)) {
                if (node.children == undefined) node.children = []

                node.children.push(child)
            }
        })
        if (!node.disabled || (node.disabled && poistetut)) {
            nodes.push(node)
        }
    })
    return nodes
}

export function filter (node : CascaderNode, keyword : string) {
    const nimike = node.label.toLowerCase().includes(keyword.toLocaleLowerCase())
    const aliKategoria = node.parent?.label.toLowerCase().includes(keyword.toLocaleLowerCase())
    const kategoria = node.parent?.parent?.label.toLowerCase().includes(keyword.toLocaleLowerCase())

    if(nimike || aliKategoria || kategoria) {
        return true
    }
    else {
        return false
    }
}
