<template>
    <template v-if="show && nimike">
        <h4>{{ nimike.nimi }}</h4>
        <el-form-item label="Ominaisuudet">
            <el-select 
            v-model="nimikeRequest.ominaisuudet" 
            value-key="id" 
            placeholder="Valitse haettavat ominaisuudet"
            multiple
            >
                <el-option 
                v-for="ominaisuus in nimike.ominaisuudet" 
                :label="ominaisuus.nimi" 
                :value="ominaisuus.id" 
                :key="ominaisuus.id"
                >
                    {{ ominaisuus.nimi }}
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="Täsmennys">
            <el-input v-model="nimikeRequest.tasmennys" placeholder="Hae täsmennyksellä"/>
        </el-form-item>
    </template>
</template>


<script setup lang="ts">
import { computed, PropType, } from 'vue';
import { useNimike } from '@/methods/nimikkeetComposables';
import { HuonekorttiNimikeSearchParams } from '@/api/models/huonekortti-nimike-search-params';

defineProps<{
    show: boolean
}>()

const nimikeRequest = defineModel({ type: Object as PropType<HuonekorttiNimikeSearchParams>, required: true })
const id = computed(() => nimikeRequest.value.id)
const { nimike } = useNimike(id)

</script>