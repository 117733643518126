<template>
    <div style="display: flex; margin-bottom: 15px;">
        <div>
            <el-button style="color: #303133" text @click="goBack">
                <el-icon>
                    <ArrowLeft />
                </el-icon>
                Takaisin
            </el-button>
        </div>
        <div style="flex: 5 3 75%; min-width: 0;">
            <div class="text-header content-header">
                {{ contentTitle }}
            </div>
            <div class="sub-title">
                <ComTooltip :content="contentSubtitle ?? ''">
                    <span>{{ contentSubtitle }}</span>
                </ComTooltip>
            </div>
        </div>
        <div style="flex-grow: 1;">
            <slot/>
        </div>
    </div>
</template>

<script setup lang="ts">
import router from '@/router'
import { ArrowLeft } from '@element-plus/icons-vue'
import ComTooltip from '../common/ComTooltip.vue'

defineProps({
    contentTitle: String,
    contentSubtitle: String
})

const goBack = () => {
    router.go(-1);
}

</script>
<style scoped>
    .page-header {
        margin-bottom: 15px;
        flex-grow: 1;
    }

    .content {
        display: flex;
        height: 32px;
        align-items: center;
        flex-wrap: wrap;
    }

    .content-header {
        display: flex;
        align-items: center;
        width: 100%;
        height: 32px;
    }

    .sub-title {
        font-size: 14px;
        font-weight: normal;
        display: flex;
        align-items: center;
        min-width: 0;
        width: 100%;
    }
    .sub-title span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
</style>
