import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { VastuuyksikkoResponse } from "@/api/models/vastuuyksikko-response"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComYllapitoHankeTableVastuuyksikot',
  props: {
    vastuuyksikot: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, { data: _ctx.vastuuyksikot }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        label: "Vastuuyksikkö",
        prop: "nimi"
      })
    ]),
    _: 1
  }, 8, ["data"]))
}
}

})