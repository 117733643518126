import { createApp } from "vue"
import Shell from "./shell.vue"
import router from "./router"
import ElementPlus from "element-plus"
import VueDiff from "vue-diff"
import fi from "../node_modules/element-plus/es/locale/lang/fi"
import { VueQueryPlugin } from '@tanstack/vue-query'
import "element-plus/theme-chalk/index.css"
import "./styles/styles.css"
import "element-plus/theme-chalk/display.css"
import "vue-diff/dist/index.css"


const app = createApp(Shell)

app.use(ElementPlus, { locale: fi })
app.use(router)
app.use(VueDiff)
app.use(VueQueryPlugin)
app.mount("#app")

app.config.errorHandler = (err) => {
    console.log(err)
}
