<template>
    <el-dialog
        v-model="show"
        close="close"
        width="30%"
        title="Haluatko luoda huonekortista mallihuonekortin hyvinvointialueen hankkeisiin?"
        append-to-body
    >
        <el-form-item label="Täsmennys">
            <el-input v-model="toiminta" />
        </el-form-item>
        <template #footer>
            <el-space>
                <ComSuccessButton @click="save" >Ok</ComSuccessButton>
                <ComCancelButton @click="show = false" >Peruuta</ComCancelButton>
            </el-space>
        </template>
    </el-dialog>
</template>

<script setup lang="ts">
import {  watch, ref,  computed, toRef } from "vue"
import { useVModel } from "@vueuse/core"
import { MallihuonekorttiRequestPut } from "@/api/models/mallihuonekortti-request-put"
import { ElMessage } from "element-plus"
import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { useAddMallihuonekortti, useUpdateMallihuonekortti } from "@/methods/mallihuonekortitComposables"
import ComSuccessButton from "@/components/common/buttons/ComSuccessButton.vue"
import ComCancelButton from "@/components/common/buttons/ComCancelButton.vue"
import { useHankeDataByVastuuyksikko } from "@/methods/hankeComposables"

const props = defineProps<{
    huonekortti: HuonekorttiResponse,
    dialog: boolean,
}>()
const emit = defineEmits(['update:dialog'])
const { hanke } = useHankeDataByVastuuyksikko(toRef(props.huonekortti.vastuuyksikko.id))
const sairaalaId = computed(() => hanke.value?.sairaala?.id)
const toiminta = ref('')
const huonekorttiId = computed(( ) => { 
    return { id: props.huonekortti.id }
})
const show = useVModel(props, 'dialog', emit)
const { addMallihuonekortti, error: addError, response: addResponse } = useAddMallihuonekortti()
const { updateMallihuonekortti, error: updateError, response: updateResponse  } = useUpdateMallihuonekortti()

const save = async () => {
    if(!sairaalaId.value) {
        ElMessage.error("Mallihuonekortin lisäys epäonnistui")
        return
    }

    addMallihuonekortti(huonekorttiId.value, sairaalaId.value)
}

watch(addResponse, (value) => {
    if(value) {
        const request: MallihuonekorttiRequestPut = { ...value, toiminta: toiminta.value }

        updateMallihuonekortti(value.id, request)
    }
})
watch(updateResponse, (value) => {
    if(value) {
        ElMessage.success("Mallihuonekortti lisätty")
        show.value = false
    }
})
watch(addError, (value) => {
    if(value) {
        ElMessage.error("Mallihuonekortin lisäys epäonnistui")
    }
})
watch(updateError, (value) => {
    if(value) {
        ElMessage.error("Mallihuonekortin päivitys epäonnistui")
    }
})
watch(()=> props.huonekortti, (value) => {
    toiminta.value = value.toiminta ?? ''
})
</script>
