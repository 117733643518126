import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response"
import ComStatusTag from "@/components/common/ComStatusTag.vue"
import { computed, watch } from "vue"
import { huonekorttiListing } from "./huonekortit"
import {
    useHylkaaHuonekortti,
    useHyvaksyHuonekortti,
    useHyvaksytaHuonekortti,
    useJulkaiseHuonekortti,
} from "@/methods/huonekorttiComposablses"
import { ElMessage } from "element-plus"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComStatus',
  props: {
    modelValue: {},
    huonekortti: {}
  },
  setup(__props: any) {

const props = __props
const { writableVastuuyksikot, paakayttaja, updateHuonekortit } = huonekorttiListing()
const {
    julkaiseHuonekortti,
    error: julkaiseError,
    loading: julkaiseLoading,
    response: julkaiseResponse,
} = useJulkaiseHuonekortti()
const {
    hylkaaHuonekortti,
    error: hylkaaError,
    loading: hylkaaLoading,
    response: hylkaaResponse,
} = useHylkaaHuonekortti()
const {
    hyvaksyHuonekortti,
    error: hyvaksyError,
    loading: hyvaksyLoading,
    response: hyvaksyResponse,
} = useHyvaksyHuonekortti()
const {
    hyvaksytaHuonekortti,
    error: hyvaksytaError,
    loading: hyvaksytaLoading,
    response: hyvaksytaResponse,
} = useHyvaksytaHuonekortti()

const readOnly = computed(() => {
    return writableVastuuyksikot.value.find((o) => o.id == props.huonekortti.vastuuyksikko.id) ? false : true
})

watch(julkaiseLoading, (value) => {
    if (!value && julkaiseResponse.value) {
        updateHuonekortit()
    }
})
watch(hylkaaLoading, (value) => {
    if (!value && hylkaaResponse.value) {
        updateHuonekortit()
    }
})
watch(hyvaksyLoading, (value) => {
    if (!value && hyvaksyResponse.value) {
        updateHuonekortit()
    }
})
watch(hyvaksytaLoading, (value) => {
    if (!value && hyvaksytaResponse.value) {
        updateHuonekortit()
    }
})
watch(julkaiseError, (value) => {
    if (value) {
        ElMessage.error("Julkaisu epäonnistui")
    }
})
watch(hylkaaError, (value) => {
    if (value) {
        ElMessage.error("Hylkäys epäonnistui")
    }
})
watch(hyvaksyError, (value) => {
    if (value) {
        ElMessage.error("Hyväksyminen epäonnistui")
    }
})
watch(hyvaksytaError, (value) => {
    if (value) {
        ElMessage.error("Hyväksyttäväksi lähettäminen epäonnistui")
    }
})

return (_ctx: any,_cache: any) => {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (!readOnly.value)
    ? (_openBlock(), _createBlock(_component_el_dropdown, {
        key: 0,
        class: "cursor"
      }, {
        dropdown: _withCtx(() => [
          _createVNode(_component_el_dropdown_menu, null, {
            default: _withCtx(() => [
              (_ctx.modelValue == _unref(HuonekorttiStatusResponse).Tyo)
                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(julkaiseHuonekortti)(_ctx.huonekortti.id)))
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" Julkaise ")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.modelValue == _unref(HuonekorttiStatusResponse).Julkaistu)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_unref(paakayttaja))
                      ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                          key: 0,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(hyvaksyHuonekortti)(_ctx.huonekortti.id)))
                        }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode(" Hyväksy ")
                          ])),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_el_dropdown_item, {
                          key: 1,
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(hyvaksytaHuonekortti)(_ctx.huonekortti.id)))
                        }, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode(" Lähetä hyväksyttäväksi ")
                          ])),
                          _: 1
                        }))
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.modelValue == _unref(HuonekorttiStatusResponse).Odottaa)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    (_unref(paakayttaja))
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(_component_el_dropdown_item, {
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(hyvaksyHuonekortti)(_ctx.huonekortti.id)))
                          }, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createTextVNode("Hyväksy")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_el_dropdown_item, {
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(hylkaaHuonekortti)(_ctx.huonekortti.id)))
                          }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode("Hylkää")
                            ])),
                            _: 1
                          })
                        ], 64))
                      : (_openBlock(), _createBlock(_component_el_dropdown_item, {
                          key: 1,
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(hylkaaHuonekortti)(_ctx.huonekortti.id)))
                        }, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode("Peruuta")
                          ])),
                          _: 1
                        }))
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(ComStatusTag, { status: _ctx.modelValue }, null, 8, ["status"])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(ComStatusTag, {
        key: 1,
        status: _ctx.modelValue
      }, null, 8, ["status"]))
}
}

})