<template>
    <el-table :data="huonekortit" border @expand-change="setDetails">
        <el-table-column type="expand">
            <template #default="scope">
                <template v-if="details(scope.row.id)">
                    <ComHakuResultDetails :huonekortti="details(scope.row.id)" />
                </template>
            </template>
        </el-table-column>
        <el-table-column
            prop="tilanimike.nimi"
            label="Tilanimike"
            width="180">
            <template #default="scope">
                <router-link
                    :to="{ name: 'Huonekortti', params: { huonekorttiId: scope.row.id } }"
                    v-slot="{ href, navigate }"
                    custom>
                    <el-link type="primary" :href="href" @click="navigate">{{ scope.row.tilanimike.nimi }}</el-link>
                </router-link>
            </template>
        </el-table-column>
        <el-table-column
            label="Täsmennys"
            min-width="250px">
            <template #default="scope">
                <span v-html="markFindCut(getSearchTermValue('tilanimike-tasmennys'), scope.row.toiminta ?? '', false)" style="margin-left: 10px"></span>
            </template>
        </el-table-column>
        <el-table-column
            prop="vastuuyksikko.nimi"
            label="Vastuuyksikkö"
            width="180">
        </el-table-column>
        <el-table-column
            label="Tilat"
            min-width="250">
            <template #default="scope">
                {{ formatTilat(scope.row.tilat, true) }}
            </template>
        </el-table-column>
    </el-table>
</template>


<script setup lang="ts">
import { useHakuComponent } from './haku';
import { useHuonekorttiFormatTilat } from '@/methods/huonekorttiComposablses';
import ComHakuResultDetails from './ComHakuResultDetails.vue';
import { ref } from 'vue';
import { HuonekorttiResponse } from '@/api/models/huonekortti-response';

const { huonekortit, markFindCut, getSearchTermValue } = useHakuComponent()
const { formatTilat } = useHuonekorttiFormatTilat()
const huonekorttiDetails = ref<HuonekorttiResponse[]>([])

const setDetails = (row: HuonekorttiResponse, expanded: boolean) => {
    if(expanded) {
        huonekorttiDetails.value.push(row)
    }
    else {
        huonekorttiDetails.value = huonekorttiDetails.value.filter(h => h.id != row.id)
    }
}

const details = (id: number) => {
    return huonekorttiDetails.value.find(h => h.id == id)
}
</script>