<template>
    <el-table :data="nimikkeet">
        <el-table-column label="Nimike" prop="nimike.nimi"/>
        <el-table-column label="Tilanimike" prop="huonekortti.tilanimike.nimi"/>
        <el-table-column label="Tilat" show-overflow-tooltip>
            <template #default="scope">
                <span>{{ formatTilat(scope.row.huonekortti.tilat) }}</span>
            </template>
        </el-table-column>
        <el-table-column label="Määrä" prop="maara" width="100px"> 
            <template #default="scope">
                <el-input 
                    v-if="editedId == scope.row.id && editedNimike" 
                    v-model="editedNimike.maara" 
                    size="small" 
                    type="number"
                    style="width: 100%">
                </el-input>
            </template>
        </el-table-column>
        <el-table-column label="Täsmennys" prop="tasmennys"> 
            <template #default="scope">
                <el-input 
                    v-if="editedId == scope.row.id && editedNimike" 
                    v-model="editedNimike.tasmennys" 
                    size="small" 
                    type="textarea"
                    style="width: 100%">
                </el-input>
            </template>
        </el-table-column>
        <el-table-column label="Ominaisuudet">
            <template #header>
                <template v-if="!showLisaaOminaisuudet">
                    Ominaisuudet <ComPlusButton size="small" @click="showLisaaOminaisuudet = true"/>
                </template>
                <template v-else>
                    <el-space>
                        <ComNimikeMuokkausLisaaOminaisuus />
                        <ComCancelIconButton @click="showLisaaOminaisuudet = false"/>
                    </el-space>
                </template>
            </template>
            <template #default="scope">
                <template v-if="editedId != scope.row.id">
                    <el-tag
                        type="info"
                        size="small"
                        style="margin-right: 2px"
                        v-for="(ominaisuus, index) in scope.row.ominaisuudet"
                        :key="index">
                        <span v-html="ominaisuus.nimi" />
                    </el-tag>
                </template>
                <el-select 
                    v-else-if="nimike && editedNimike" 
                    v-model="editedNimike.ominaisuudet" 
                    value-key="id"
                    multiple 
                    size="small"
                    collapse-tags 
                    placeholder="Valitse ominaisuudet" 
                    style="width: 100%">
                    <el-option
                        v-for="ominaisuus in nimike.ominaisuudet"
                        :key="ominaisuus.id"
                        :label="ominaisuus.nimi"
                        :value="ominaisuus">
                    </el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column label="Muokkaa" width="100px">
            <template #default="scope">
                <template v-if="loading">
                    <el-icon class="is-loading">
                        <Loading />
                    </el-icon>
                </template>
                <template v-else-if="updateSuccess.includes(scope.row.id)">
                    <el-icon class="is-success">
                        <Check />
                    </el-icon>
                </template>
                <template v-else-if="editedId == scope.row.id">
                    <ComCancelIconButton size="small" @click="peruutaMuokkaus"/>
                    <ComSaveButton size="small" @click="tallennaMuokkaus"/>
                </template>
                <ComEditButton v-else type="primary" size="small" @click="muokkaaNimiketta(scope.row)"/>
            </template>
        </el-table-column>
    </el-table>
</template>

<script setup lang="ts">
import ComEditButton from '@/components/common/buttons/ComEditButton.vue';
import { useNimikeMuokkausComponent } from './nimikeMuokkaus';
import { useHuonekorttiFormatTilat } from '@/methods/huonekorttiComposablses';
import { useNimike } from '@/methods/nimikkeetComposables';
import ComCancelIconButton from '@/components/common/buttons/ComCancelIconButton.vue';
import ComSaveButton from '@/components/common/buttons/ComSaveButton.vue';
import { Loading, Check } from '@element-plus/icons-vue';
import ComPlusButton from '@/components/common/buttons/ComPlusButton.vue';
import ComNimikeMuokkausLisaaOminaisuus from './ComNimikeMuokkausLisaaOminaisuus.vue';

const { nimikkeet, editedNimike, editedId, nimikeId, loading, updateSuccess, showLisaaOminaisuudet, muokkaaNimiketta, peruutaMuokkaus, tallennaMuokkaus } = useNimikeMuokkausComponent()
const { formatTilat } = useHuonekorttiFormatTilat()
const { nimike } = useNimike(nimikeId)
</script>