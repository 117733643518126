import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response"
import { useHankeDataByVastuuyksikko } from "@/methods/hankeComposables"
import { useHuonekortti } from "@/methods/huonekorttiComposablses"
import { useOikeudet } from "@/methods/kayttajaOikeusComposables"
import { useNimikeKategoria } from "@/methods/nimikkeetComposables"
import HuonekorttiEdited from "@/types/HuonekorttiEdited"
import { computed, onBeforeUnmount, ref, toRef, watch } from "vue"
import { NavigationGuardNext, onBeforeRouteLeave, onBeforeRouteUpdate, useRoute } from "vue-router"

interface state {
    edited: HuonekorttiEdited,
    showMuutokset: boolean,
    showHanketiedot: boolean,
}

const state = ref<state>({
    edited: {
        nimikeLisays: false,
        suunnittelutiedot: false,
        toimintatiedot: false,
    },
    showMuutokset: false,
    showHanketiedot: false,
})

export function huonekorttiPage() {
    const route = useRoute()
    const id = computed(() => Number(route.params.huonekorttiId))
    if (!id.value) {
        throw new Error("Huonekortti id:tä ei ole määritetty.")
    }
    const {
        huonekortti,
        vastuuyksikko,
        kommentitCount,
        liitteetCount,
        tilatString,
        loading,
    } = useHuonekortti(id)
    const vastuuyksikkoId = computed(() => huonekortti.value?.vastuuyksikko.id)
    const { hanke } = useHankeDataByVastuuyksikko(vastuuyksikkoId)
    const { nimikeKategoriat } = useNimikeKategoria(toRef(true))
    const { writableVastuuyksikot, paakayttajaHankkeet, superUser } = useOikeudet()
    const kirjoitus = computed(() => superUser.value || writableVastuuyksikot.value?.some((v) => v == vastuuyksikko.value?.id))
    const paakayttaja = computed(() => superUser.value || paakayttajaHankkeet.value?.some((h) => h === hanke.value?.id))
    const isEdited = computed(
        () => state.value.edited.nimikeLisays || state.value.edited.suunnittelutiedot || state.value.edited.toimintatiedot
    )
    const isHyvaksytty = computed(() => huonekortti.value?.status == HuonekorttiStatusResponse.Hyvaksytty)
    const showHanketiedot = computed({
        get: () => state.value.showHanketiedot,
        set: (value) => {
            state.value.showHanketiedot = value
        }
    })
    const showMuutokset = computed({
        get: () => state.value.showMuutokset,
        set: (value) => {
            state.value.showMuutokset = value
        }
    })
    const headerTitle = computed(() => huonekortti.value?.tilanimike?.nimi ?? "")
    const headerSubtitle = computed(
        () => (huonekortti.value?.toiminta ?? "") + " (" + tilatString.value + ")"
    )

    onBeforeRouteLeave((to, from, next) => pageChangeConfirm(next))
    onBeforeRouteUpdate((to, from, next) => pageChangeConfirm(next))
    onBeforeUnmount(() => {
        if (isEdited.value) {
            resetEdited()
        }
    })

    const setEdited = (key: keyof HuonekorttiEdited, value: boolean) => {
        state.value.edited[key] = value
    }
    const resetEdited = () => {
        state.value.edited = {
            nimikeLisays: false,
            suunnittelutiedot: false,
            toimintatiedot: false,
        }

        window.removeEventListener("beforeunload", beforeWindowUnload)
    }
    const pageChangeConfirm = (next: NavigationGuardNext) => {
        if (!isEdited.value) {
            return next()
        } else {
            const confirm = window.confirm(
                "Sinulla on tallentamattomia muutoksia, jotka katoavat jos siirryt sivulta pois. Haluatko jatkaa?"
            )

            if (confirm) {
                resetEdited()
            }

            return next(confirm)
        }
    }
    const beforeWindowUnload = (e: BeforeUnloadEvent) => {
        e.preventDefault()

        return ""
    }

    watch(isEdited, () => {
        if (isEdited.value) {
            window.addEventListener("beforeunload", beforeWindowUnload)
        } else {
            window.removeEventListener("beforeunload", beforeWindowUnload)
        }
    })
    watch(id, () => {
        if (isEdited.value) {
            resetEdited()
        }
    })

    return {
        huonekortti,
        headerTitle,
        headerSubtitle,
        showHanketiedot,
        showMuutokset,
        liitteetCount,
        kommentitCount,
        hanke,
        loading,
        nimikeKategoriat,
        isEdited,
        isHyvaksytty,
        kirjoitus,
        paakayttaja,
        setEdited,
    }
}
