<template>
    <template v-if="data">
        <span class="node_title" @click="loadHuonekortit()">
            <el-icon color="rgb(192, 196, 204)" v-if="opened"><CaretBottom /></el-icon>
            <el-icon color="rgb(192, 196, 204)" v-else><CaretRight /></el-icon>
            {{ data.nimi }}
        </span>
        <div class="break"></div>
        <span v-show="opened">
            <el-input placeholder="Suodata" size="small" v-model.lazy="filterInput"></el-input>
            <el-scrollbar max-height="400px" @scroll="scroll" ref="scrollbarRef" class="side-menu-scroll">
                <div v-for="page in pages" :key="page" class="el-tree-node__children huonekortit_nodes">
                    <ComSideMenuHuonekortit
                        :id="data.id"
                        :page="page"
                        :filter="filter"
                        :checkbox="checkbox"
                        v-if="vastuuyksikko"
                        @totalCount="setTotalCount" />
                </div>
            </el-scrollbar>
        </span>
    </template>
</template>

<script setup lang="ts">
import { VastuuyksikkoResponse } from "@/api/models/vastuuyksikko-response"
import { ref, watch } from "vue"
import { CaretRight, CaretBottom } from "@element-plus/icons-vue"
import ComSideMenuHuonekortit from "./ComSideMenuHuonekortit.vue"
import type { ElScrollbar } from "element-plus"

defineProps<{
    data: VastuuyksikkoResponse
    checkbox: boolean
}>()
const vastuuyksikko = ref(false)
const opened = ref(false)
const pages = ref(1)
const total = ref<number | null>(null)
const perPage = ref(30)
const filterInput = ref("")
const filter = ref("")
const awaitFilter = ref(false)
const scrollbarRef = ref<InstanceType<typeof ElScrollbar>>()

interface ScrollValues {
    scrollTop: number
    scrollLeft: number
}

watch(filterInput, () => {
    pages.value = 1
    scrollbarRef.value?.setScrollTop(0)

    if (!awaitFilter.value) {
        setTimeout(() => {
            filter.value = filterInput.value
            awaitFilter.value = false
        }, 1000)
    }
    awaitFilter.value = true
})

const loadHuonekortit = () => {
    vastuuyksikko.value = true
    opened.value = !opened.value
}

const scroll = ({ scrollTop }: ScrollValues) => {
    let p = 0

    if (total.value) {
        p = Math.ceil(total.value / perPage.value)
    }

    if (scrollTop > 220 * pages.value && pages.value < p) {
        pages.value++
    }
}

const setTotalCount = (val: number, val2: number) => {
    total.value = val
    pages.value = val2
}

</script>
