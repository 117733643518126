import { HankeResponse } from "@/api/models/hanke-response"
import { KayttajaResponse } from "@/api/models/kayttaja-response"
import { VastuuyksikkoRequest } from "@/api/models/vastuuyksikko-request"
import { useHankkeet } from "@/methods/hankeComposables"
import { useAddPaakayttaja, useDeletePaakayttaja, useHankePaakayttajat } from "@/methods/paakayttajaComposables"
import { useAddVastuuyksikko } from "@/methods/vastuuyksikkoComposables"
import { ElMessage, ElMessageBox } from "element-plus"
import { computed, ref, watch } from "vue"

const selectedHanke = ref<HankeResponse>()

export function useYllapitoComponent() {
    const { hankkeet } = useHankkeet()
    const { addVastuuyksikko: _addVastuuyksikko, error: addVastuuyksikkoError } = useAddVastuuyksikko()
    const hankeId = computed(() => selectedHanke.value?.id)
    const { paakayttajat } = useHankePaakayttajat(hankeId)
    const { deletePaakayttaja: _deletePaakayttaja, error: paakayttajaDeleteError } = useDeletePaakayttaja()
    const { addPaakayttaja: _addPaakayttaja, error: paakayttajaAddError } = useAddPaakayttaja()
    const kayttajatSearched = ref<KayttajaResponse[]>([])

    const addVastuuyksikko = async (vastuuyksikko: VastuuyksikkoRequest) => {
        vastuuyksikko.hanke = selectedHanke.value

        _addVastuuyksikko(vastuuyksikko)
    }

    const setSelectedHanke = (id: number) => {
        selectedHanke.value = hankkeet.value?.find((h) => h.id == id)
    }

    const deletePaakayttaja = async (id: number) => {
        const confirm = await ElMessageBox.confirm("Haluatko varmasti poistaa pääkäyttäjän?", "warning", {
            confirmButtonText: "Kyllä",
            cancelButtonText: "Peruuta",
            type: "warning",
        })

        if (confirm) {
            _deletePaakayttaja(id)
        }
    }

    const addPaakayttaja = async (id: number) => {
        if (selectedHanke.value) {
            _addPaakayttaja(selectedHanke.value.id, id)
        }
    }

    watch(hankkeet, (value) => {
        if (selectedHanke.value) {
            selectedHanke.value = value?.find((h) => h.id == selectedHanke.value?.id)
        }
    })

    watch(addVastuuyksikkoError, (value) => {
        if (value) {
            ElMessage.error("Vastuuyksikön lisääminen epäonnistui")
        }
    })

    watch(paakayttajaDeleteError, (value) => {
        if (value) {
            ElMessage.error("Pääkäyttäjän poistaminen epäonnistui")
        }
    })

    watch(paakayttajaAddError, (value) => {
        if (value) {
            ElMessage.error("Pääkäyttäjän lisääminen epäonnistui")
        }
    })

    return {
        hankkeet,
        selectedHanke,
        paakayttajat,
        kayttajatSearched,
        addVastuuyksikko,
        setSelectedHanke,
        addPaakayttaja,
        deletePaakayttaja,
    }
}