<template>
    <h3 v-if="showHeading(2)">Tila</h3>
    <el-form :model="tila">
        <el-row :gutter="20">
            <el-col :span="12" v-if="isSelected('tilatunnus')">
                <el-form-item label="Tilatunnus">
                    <el-input v-model="tila.tilatunnus"/>
                </el-form-item>
            </el-col>
            <el-col :span="12" v-if="isSelected('pinta-ala')">
                <el-form-item label="Pinta-ala">
                    <el-col :span="12">
                        <el-input type="number" v-model="tila['pinta-ala']['pinta-ala']"/>
                    </el-col>
                    <el-col :span="12">
                        <el-radio-group v-model="tila['pinta-ala'].operator" size="small">
                            <el-radio-button label="Yhtä kuin" :value="Operator.Eq" />
                            <el-radio-button label="Pienempi kuin" :value="Operator.Lt" />
                            <el-radio-button label="Isompi kuin" :value="Operator.Gt" />
                        </el-radio-group>
                    </el-col>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useHakuComponent } from './haku';
import { Operator } from '@/api/models/operator';

const { searchForm, isSelected, showHeading } = useHakuComponent()
const tila = ref(searchForm.value.tila)

watch(() => searchForm.value.tila, (value) => {
    tila.value = value
}, { immediate: true })

</script>