import { HuonekorttiNimikeSearchParams } from "@/api/models/huonekortti-nimike-search-params"
import { HuonekorttiNimikeWithHuonekorttiResponse } from "@/api/models/huonekortti-nimike-with-huonekortti-response"
import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { HuonekorttiSahkoluokka } from "@/api/models/huonekortti-sahkoluokka"
import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response"
import { KategoriaResponse } from "@/api/models/kategoria-response"
import { Operator } from "@/api/models/operator"
import { Paging } from "@/api/models/paging"
import { TilaResponseExtended } from "@/api/models/tila-response-extended"
import { searchHuonekortit } from "@/methods/huonekortitComposables"
import { searchNimikkeet } from "@/methods/nimikkeetComposables"
import { searchTilat } from "@/methods/tilaComposables"
import { hankePage } from "@/views/hanke"
import { useLocalStorage } from "@vueuse/core"
import { ElMessage } from "element-plus"
import sanitize from "sanitize-html"
import { computed, ref, watch, watchEffect } from "vue"

const searchForm = ref({
    huonekortti: {
        "vastuuyksikot": [] as number[],
        "tilanimike-tasmennys": undefined as string | undefined,
        "toiminnan-kuvaus": undefined as string | undefined,
        "toiminnan-vaatimukset": undefined as string | undefined,
        "henkilomaara-norm": NaN as number | undefined,
        "henkilomaara-max": NaN as number | undefined,
        "sahkoluokka": undefined as HuonekorttiSahkoluokka[] | undefined,
        "toiminta-aika": undefined as string | undefined,   
        "tilanimike": [] as string[],
        "status": undefined as HuonekorttiStatusResponse[] | undefined,
    },
    nimike: [] as HuonekorttiNimikeSearchParams[],
    tila: {
        tilatunnus: undefined as string | undefined,
        "pinta-ala": {
            "pinta-ala": NaN as number | undefined,
            operator: Operator.Eq as Operator | undefined,
        },
    },
    paging: {
        per_page: useLocalStorage("huonekorttiPageSize", 30).value,
        page: 1
    } as Paging
})

const selectedTerms = ref<string[]>([])
const selectedNodes = ref<[number,number,number][]>([])
const searchType = ref(1)
const tilat = ref<TilaResponseExtended[]>([])
const huonekortit = ref<HuonekorttiResponse[]>([])
const nimikkeet = ref<HuonekorttiNimikeWithHuonekorttiResponse[]>([])
const searchTotal = ref(0)
const searchLoading = ref(false)

export function useHakuComponent() {
    const searchTerms = ref([
        {
            label: "Toimintatiedot",
            options: [
                {value: "tilanimike", label: "Tilanimike"},
                {value: "tilanimike-tasmennys", label: "Tilanimikkeen täsmennys"},
                {value: "toiminnan-kuvaus", label: "Toiminnan kuvaus"},
                {value: "toiminnan-vaatimukset", label: "Toiminnan vaatimukset"},
                {value: "henkilomaara-norm", label: "Henkilömäärä normaali"},
                {value: "henkilomaara-max", label: "Henkilömäärä maksimi"},
                {value: "sahkoluokka", label: "Sähköluokka"},
                {value: "toiminta-aika", label: "Toiminta-aika"},
            ]     
        },
        {
            label: "Suunnittelutiedot",
            options: [
                {value: "nimike", label: "Nimike"},
            ]
        },
        {
            label: "Tilat",
            options: [
                {value: "tilatunnus", label: "Tilatunnus"},
                {value: "pinta-ala", label: "Pinta-ala"},
            ]
        }
    ])
    const { selectedVastuuyksikkoIds, id } = hankePage()
    const { search: haeHuonekortit, huonekortit: huonekortitResponse, total: huonekortitTotal, error: huonekortitError, loading: huonekortitLoading } = searchHuonekortit()
    const { search: haeTilat, tilat: tilatResponse, loading: tilatLoading, total: tilatTotal, error: tilatError } = searchTilat()
    const { search: haeNimikeet, nimikkeet: nimikkeetResponse, loading: nimikkeetLoading, total: nmimikkeetTotal, error: nimikkeetError } = searchNimikkeet()

    const hae = (resetPage : boolean = false) => {
        const huonekortti = searchForm.value.huonekortti
        
        if(resetPage) {
            searchForm.value.paging.page = 1
        }

        if(selectedVastuuyksikkoIds.value.length == 0) {
            ElMessage.error("Valitse vastuuyksiköt")       
        } else if((huonekortti["toiminnan-kuvaus"] && huonekortti["toiminnan-kuvaus"].length < 3)
        || (huonekortti["toiminnan-vaatimukset"] && huonekortti["toiminnan-vaatimukset"].length < 3)
        || (huonekortti["tilanimike-tasmennys"] && huonekortti["tilanimike-tasmennys"].length < 3)) {
            ElMessage.error("Hakusanan tulee olla vähintään 3 merkkiä pitkä")
        } else {
            searchForm.value.huonekortti.vastuuyksikot = selectedVastuuyksikkoIds.value

            if(searchType.value == 1) {
                haeHuonekortit(searchForm.value)
            } else if (searchType.value == 2) {
                haeTilat(searchForm.value)
            } else if (searchType.value == 3) {
                haeNimikeet(searchForm.value)
            }
        }
    }
    const markFindCut = (term: string | undefined, value: string, cut: boolean) => {
        let formattedVal = sanitize(value, {allowedTags: ["br", "p"], allowedAttributes: {}})

        if(!value || !term) {
            return formattedVal
        }

        let index = formattedVal.toLowerCase().search(term.toLowerCase())
        let endIndex = index + term.length
        const spanBegining = "<span style='background:#e6ffe6;'>"
        const spanEnd = "</span>"
        const offset = spanBegining.length + spanEnd.length + 1

        while(index >= 0) {
            formattedVal = formattedVal.slice(0, index) + spanBegining + formattedVal.slice(index, endIndex) + spanEnd + formattedVal.slice(endIndex)
            index = formattedVal.toLowerCase().indexOf(term.toLowerCase(), index + offset)
            endIndex = index + term.length
        }

        if(!cut) {
            return formattedVal
        } 

        const firstIndex = formattedVal.toLowerCase().search(term.toLowerCase()) - spanBegining.length
        const termLength = term.length

        return cutString(formattedVal, firstIndex, termLength)
    }
    const cutString = (val: string, index: number, termLength: number) => {
        if(!val) {
            return ""
        } else if(val.length < 5000) {
            return val
        } else if(val.length - termLength > 5000) {
            return (index != 0 ? "..." : "") + val.slice(index, index + 5000) + "..."
        } else {
            return (index != 0 ? "..." : "") + val.slice(index) 
        }
    }
    const isSelected = (term : string) => {
        return selectedTerms.value.includes(term)
    }
    const showHeading = (val : number) => {
        if(selectedTerms.value.some(s => searchTerms.value[val].options.map(o => o.value).includes(s))) {
            return true
        } else {
            return false
        }
    }
    const setKategoria = (id: number, kategoriat : KategoriaResponse[]) => {
        const k = kategoriat.find(n => n.children?.find(c => c.id === id))
        const a = k?.children?.find(c => c.id === id)
    
        return k?.nimi + ' / ' + a?.nimi
    } 
    const getSearchTermValue = (term: string, nimikeId? : number) => {
        if(term == "toiminnan-kuvaus" || term == "toiminnan-vaatimukset" || term == "tilanimike-tasmennys") {
            return searchForm.value.huonekortti[term]
        } else if(term == "tasmennys" && nimikeId) {
            return searchForm.value.nimike.find(n => n.id == nimikeId)?.tasmennys
        }
    }
    const formatResponse = () => {
        huonekortitResponse.value = []
        tilatResponse.value = []
        nimikkeetResponse.value = []

        huonekortitTotal.value = 0
        tilatTotal.value = 0
        nmimikkeetTotal.value = 0
    }
    const formatSelection = () => {
        searchForm.value = {
            huonekortti: {
                "vastuuyksikot": [] as number[],
                "tilanimike-tasmennys": undefined as string | undefined,
                "toiminnan-kuvaus": undefined as string | undefined,
                "toiminnan-vaatimukset": undefined as string | undefined,
                "henkilomaara-norm": NaN as number | undefined,
                "henkilomaara-max": NaN as number | undefined,
                "sahkoluokka": undefined as HuonekorttiSahkoluokka[] | undefined,
                "toiminta-aika": undefined as string | undefined,   
                "tilanimike": [] as string[],
                "status": undefined as HuonekorttiStatusResponse[] | undefined,
            },
            nimike: [] as HuonekorttiNimikeSearchParams[],
            tila: {
                tilatunnus: undefined as string | undefined,
                "pinta-ala": {
                    "pinta-ala": NaN as number | undefined,
                    operator: Operator.Eq as Operator | undefined,
                },
            },
            paging: {
                per_page: useLocalStorage("huonekorttiPageSize", 30).value,
                page: 1
            } as Paging
        }
        selectedTerms.value = []
        selectedNodes.value = []
        searchType.value = 1
    }

    watch(selectedVastuuyksikkoIds, () => {
        formatResponse()
    })
    watch(huonekortitResponse, (value) => {
        huonekortit.value = value
        searchTotal.value = huonekortitTotal.value
    })
    watch(tilatResponse, (value) => {
        tilat.value = value
        searchTotal.value = tilatTotal.value
    })
    watch(nimikkeetResponse, (value) => {
        nimikkeet.value = value
        searchTotal.value = nmimikkeetTotal.value
    })
    watch(searchType, () => {
        formatResponse()

        if(searchType.value == 3 && !selectedTerms.value.some(s => s == "nimike")) {
            selectedTerms.value.push("nimike")
        } else if(searchType.value != 3 
            && selectedTerms.value.some(s => s == "nimike")
            && searchForm.value.nimike.length == 0) {
            selectedTerms.value = selectedTerms.value.filter(s => s != "nimike")
        }
    })
    watch(id, () => {
        formatResponse()
        formatSelection()
    })
    watchEffect(() => {
        searchLoading.value = huonekortitLoading.value || tilatLoading.value || nimikkeetLoading.value
    })
    watchEffect(() => {
        if(tilatError.value || huonekortitError.value || nimikkeetError.value) {
            ElMessage.error("Haku epäonnistui")
        }
    })

    return {
        searchForm,
        searchTerms,
        selectedTerms,
        huonekortit,
        tilat,
        nimikkeet,
        searchTotal,
        searchLoading,
        nimikkeetLoading,
        searchType,
        selectedNodes,
        getSearchTermValue,
        isSelected,
        hae,
        markFindCut,
        showHeading,
        setKategoria
    }
}