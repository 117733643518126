import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { Ref, computed, ref, watch } from "vue"
import HuonekorttiListingParams from "@/types/HuonekorttiListingParams"
import http from "@/http-common"
import { useMutation, useQuery } from "@tanstack/vue-query"
import { AxiosResponseHeaders } from "axios"
import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response"
import { HuonekorttiSearchRequest } from "@/api/models/huonekortti-search-request"

export function useHuonekortit(val?: Ref<HuonekorttiListingParams>) {
    const huonekortit = ref<HuonekorttiResponse[]>([])
    const total = ref(0)
    const key = ref<any[]>([])
    const paramsObject =
        val ??
        ref<HuonekorttiListingParams>({
            paging: {
                pageSize: 30,
                currentPage: 1,
            },
            sort: undefined,
            order: undefined,
            vastuuyksikot: [],
            nimikkeet: false,
            tilanimike: [],
            tilanimikeTasmennys: "",
            tilatunnus: "",
            status: [],
            poistettu: undefined,
        })
    const filterAll = ref<string>("")
    const enabled = ref(false)
    const currentPage = computed({
        get: () => paramsObject.value.paging?.currentPage || 1,
        set: (val: number) => {
            if(paramsObject.value.paging) {
                paramsObject.value.paging.currentPage = val
            } else {
                paramsObject.value.paging = { pageSize: 30, currentPage: val }
            }
        },
    })
    const pageSize = computed({
        get: () => paramsObject.value.paging?.pageSize || 30,
        set: (val: number) => {
            if(paramsObject.value.paging) {
                paramsObject.value.paging.pageSize = val
            } else {
                paramsObject.value.paging = { pageSize: val, currentPage: 1 }
            }
        },
    })
    const filter = computed({
        get: () => filterAll.value,
        set: (val: string) => {
            filterAll.value = val
            setAllFilters(val)
        },
    })
    const status = computed({
        get: () => paramsObject.value.status,
        set: (val?: HuonekorttiStatusResponse[]) => {
            paramsObject.value.status = val ?? []
        },
    })

    const getUrlParams = () => {
        let order = ""

        if(paramsObject.value.order) {
            if (paramsObject.value.order.toString() === "ascending") {
                order = "asc"
            } else if (paramsObject.value.order.toString() === "descending") {
                order = "desc"
            }
        }

        const params = Object.assign(
            {},
            paramsObject.value.vastuuyksikot ? { vastuuyksikot: paramsObject.value.vastuuyksikot.join(",") } : null,
            paramsObject.value.paging?.currentPage ? { page: paramsObject.value.paging.currentPage } : null,
            paramsObject.value.paging?.pageSize ? { per_page: paramsObject.value.paging.pageSize } : null,
            paramsObject.value.sort ? { sort: paramsObject.value.sort } : null,
            paramsObject.value.order ? { order: order } : null,
            paramsObject.value.tilanimike && paramsObject.value.tilanimike.length > 0 ? { tilanimike: paramsObject.value.tilanimike.join(",") } : null,
            paramsObject.value.tilanimikeTasmennys
                ? { "tilanimike-tasmennys": paramsObject.value.tilanimikeTasmennys }
                : null,
            paramsObject.value.tilatunnus ? { tilatunnus: paramsObject.value.tilatunnus } : null,
            paramsObject.value.nimikkeet ? { nimikkeet: paramsObject.value.nimikkeet } : null,
            paramsObject.value.status && paramsObject.value.status.length > 0
                ? { status: paramsObject.value.status.join(",") }
                : null,
            paramsObject.value.poistettu ? { poistettu: paramsObject.value.poistettu } : null,
            paramsObject.value.toiminnanKuvaus ? { "toiminnan-kuvaus": paramsObject.value.toiminnanKuvaus } : null,
            paramsObject.value.toiminnanVaatimukset ? { "toiminnan-vaatimukset": paramsObject.value.toiminnanVaatimukset } : null,
            paramsObject.value.henkilomaaraNorm ? { "henkilomaara-norm": paramsObject.value.henkilomaaraNorm } : null,
            paramsObject.value.henkilomaaraMax ? { "henkilomaara-max": paramsObject.value.henkilomaaraMax } : null,
            paramsObject.value.sahkoluokka && paramsObject.value.sahkoluokka.length > 0 ? { sahkoluokka: paramsObject.value.sahkoluokka.join(",") } : null,
            paramsObject.value.toimintaAika ? { "toiminta-aika": paramsObject.value.toimintaAika } : null
        )

        return ["huonekortit", params]
    }
    const setAllFilters = (val: string) => {
        if(val) {
            paramsObject.value.tilanimike = [val]
        } else {
            paramsObject.value.tilanimike = undefined
        }
        paramsObject.value.tilanimikeTasmennys = val
        paramsObject.value.tilatunnus = val
    }
    const fetcher = async () => {
        const response = await http.get("/huonekortit", { params: key.value[1] })

        return {
            data: response.data as HuonekorttiResponse[],
            headers: response.headers as AxiosResponseHeaders,
        }
    }

    const { data, error, isLoading: loading, refetch: mutate } = useQuery({ 
        queryKey: key, 
        queryFn: () => fetcher(), 
        enabled: enabled
    })

    watch(
        paramsObject,
        () => {
            key.value = getUrlParams()
        },
        { deep: true }
    )

    watch(paramsObject, () => {
        if(paramsObject.value.vastuuyksikot != undefined && paramsObject.value.vastuuyksikot?.length !== 0) {
            enabled.value = true
        } else {
            enabled.value = false
        }
    }, { deep: true }) 

    watch(data, () => {
        if (data) {
            huonekortit.value = data.value?.data ?? []
            total.value = data.value?.headers["page-total-count"]
                ? parseInt(data.value?.headers["page-total-count"])
                : 0
        }
    })

    return {
        huonekortit: huonekortit,
        error: error,
        total: total,
        paramsObject,
        loading,
        currentPage,
        pageSize,
        filter,
        status,
        mutate,
        setAllFilters,
    }
}

export function searchHuonekortit() {
    const huonekortit = ref<HuonekorttiResponse[]>([])
    const total = ref(0)

    const search = (params: HuonekorttiSearchRequest) => {
        mutate(params)
    }

    
    const fetcher = async (request: HuonekorttiSearchRequest) => {
        const response = await http.post("huonekortit/hae", request)

        return {
            data: response.data as HuonekorttiResponse[],
            headers: response.headers as AxiosResponseHeaders,
        }
    }

    const { data, error, isPending: loading, mutate } = useMutation({
        mutationFn: (request: HuonekorttiSearchRequest) => fetcher(request),
    })

        watch(data, () => {
        if (data) {
            huonekortit.value = data.value?.data ?? []
            total.value = data.value?.headers["page-total-count"]
                ? parseInt(data.value?.headers["page-total-count"])
                : 0
        }
    })

    return {
        huonekortit,
        total,
        error,
        loading,
        search,
    }
}