import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, isRef as _isRef, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

import ComHakuToimintatiedot from './ComHakuToimintatiedot.vue';
import { useHakuComponent } from './haku';
import ComHakuSuunnittelutiedot from './ComHakuSuunnittelutiedot.vue';
import ComSuccessButton from '@/components/common/buttons/ComSuccessButton.vue';
import ComHuonekorttiResultTable from './ComHuonekorttiResultTable.vue';
import ComTilaResultTable from './ComTilaResultTable.vue';
import ComLoading from '@/components/common/ComLoading.vue';
import ComPagination from '@/components/common/ComPagination.vue';
import { watch } from 'vue';
import ComHakuTilat from './ComHakuTilat.vue';
import { Operator } from '@/api/models/operator';
import ComNimikeResultTable from './ComNimikeResultTable.vue';
import { useNimikeSelection } from './nimikeSelection';
import ComNimikeMuokkaus from './muokkaus/ComNimikeMuokkaus.vue';
import ComCancelButton from '@/components/common/buttons/ComCancelButton.vue';
import { useNimikeMuokkausComponent } from './muokkaus/nimikeMuokkaus';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHaku',
  setup(__props) {

const { searchType, searchLoading, searchTerms, selectedTerms, searchTotal, searchForm, hae } = useHakuComponent()
const { showNimikeEditModal, hideNimikeEditModal, selectedNimikkeet, showMuokkaa, nimikeEditModalVisible } = useNimikeSelection()
const { tallennaNimikkeet } = useNimikeMuokkausComponent()

watch(selectedTerms, (value) => {
    if(!value.includes('tilanimike')) {
        searchForm.value.huonekortti.tilanimike = []
    }
    if(!value.includes('tilanimike-tasmennys')) {
        searchForm.value.huonekortti['tilanimike-tasmennys'] = undefined
    }
    if(!value.includes('toiminnan-kuvaus')) {
        searchForm.value.huonekortti['toiminnan-kuvaus'] = undefined
    }
    if(!value.includes('toiminnan-vaatimukset')) {
        searchForm.value.huonekortti['toiminnan-vaatimukset'] = undefined
    }
    if(!value.includes('henkilomaara-norm')) {
        searchForm.value.huonekortti['henkilomaara-norm'] = undefined
    }
    if(!value.includes('henkilomaara-max')) {
        searchForm.value.huonekortti['henkilomaara-max'] = undefined
    }
    if(!value.includes('sahkoluokka')) {
        searchForm.value.huonekortti.sahkoluokka = undefined
    }
    if(!value.includes('toiminta-aika')) {
        searchForm.value.huonekortti['toiminta-aika'] = undefined
    }
    if(!value.includes('status')) {
        searchForm.value.huonekortti.status = undefined
    }
    if(!value.includes('nimike')) {
        searchForm.value.nimike = []
    }
    if(!value.includes('pinta-ala')) {
        searchForm.value.tila['pinta-ala'] = { operator: Operator.Eq, 'pinta-ala': undefined }
    }
    if(!value.includes('tilatunnus')) {
        searchForm.value.tila.tilatunnus = undefined
    }
})


return (_ctx: any,_cache: any) => {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_option_group = _resolveComponent("el-option-group")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_card, {
      class: "el-card-blank",
      shadow: "never"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, { label: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_radio_group, {
              modelValue: _unref(searchType),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(searchType) ? (searchType).value = $event : null))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio_button, {
                  label: "Huonekortti haku",
                  value: 1
                }),
                _createVNode(_component_el_radio_button, {
                  label: "Tila haku",
                  value: 2
                }),
                _createVNode(_component_el_radio_button, {
                  label: "Nimike haku",
                  value: 3
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "Valitse hakukentät" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              multiple: "",
              modelValue: _unref(selectedTerms),
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(selectedTerms) ? (selectedTerms).value = $event : null))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(searchTerms), (group) => {
                  return (_openBlock(), _createBlock(_component_el_option_group, {
                    key: group.label,
                    label: group.label
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.options, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.value,
                          label: item.label,
                          value: item.value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["label"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(ComHakuToimintatiedot),
        _createVNode(ComHakuSuunnittelutiedot),
        _createVNode(ComHakuTilat),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(ComSuccessButton, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(hae)(true)))
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Hae")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_divider, { "border-style": "none" }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                (_unref(showMuokkaa))
                  ? (_openBlock(), _createBlock(ComSuccessButton, {
                      key: 0,
                      onClick: _unref(showNimikeEditModal)
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("Muokkaa valittuja nimikkeitä")
                      ])),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 18 }, {
          default: _withCtx(() => [
            _createVNode(ComPagination, {
              total: _unref(searchTotal),
              "page-size": _unref(searchForm).paging.per_page,
              "onUpdate:pageSize": _cache[3] || (_cache[3] = ($event: any) => ((_unref(searchForm).paging.per_page) = $event)),
              "current-page": _unref(searchForm).paging.page,
              "onUpdate:currentPage": _cache[4] || (_cache[4] = ($event: any) => ((_unref(searchForm).paging.page) = $event)),
              style: {"float":"right"},
              onChange: _cache[5] || (_cache[5] = ($event: any) => (_unref(hae)(false)))
            }, null, 8, ["total", "page-size", "current-page"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 24 }, {
          default: _withCtx(() => [
            (_unref(searchLoading))
              ? (_openBlock(), _createBlock(ComLoading, { key: 0 }))
              : (_unref(searchType) == 1)
                ? (_openBlock(), _createBlock(ComHuonekorttiResultTable, { key: 1 }))
                : (_unref(searchType) == 2)
                  ? (_openBlock(), _createBlock(ComTilaResultTable, { key: 2 }))
                  : (_unref(searchType) == 3)
                    ? (_openBlock(), _createBlock(ComNimikeResultTable, { key: 3 }))
                    : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_drawer, {
      title: "Muokkaa",
      modelValue: _unref(nimikeEditModalVisible),
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(nimikeEditModalVisible) ? (nimikeEditModalVisible).value = $event : null)),
      size: "70%",
      "close-on-click-modal": false,
      "destroy-on-close": true
    }, {
      footer: _withCtx(() => [
        _createVNode(ComCancelButton, { onClick: _unref(hideNimikeEditModal) }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode("Peruuta")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(ComSuccessButton, { onClick: _unref(tallennaNimikkeet) }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode("Tallenna")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(ComNimikeMuokkaus, { nimikeet: _unref(selectedNimikkeet) }, null, 8, ["nimikeet"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})