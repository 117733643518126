import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

import { useNimikeKategoria } from '@/methods/nimikkeetComposables';
import { useHakuComponent } from './haku'
import { ref, watch } from 'vue';
import { HuonekorttiNimikeWithHuonekorttiResponse } from '@/api/models/huonekortti-nimike-with-huonekortti-response';
import { useHuonekorttiFormatTilat } from '@/methods/huonekorttiComposablses';
import ComHakuResultDetails from './ComHakuResultDetails.vue';
import { HuonekorttiResponse } from '@/api/models/huonekortti-response';
import { useNimikeSelection } from './nimikeSelection';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComNimikeResultTable',
  setup(__props) {

const { nimikkeet, markFindCut, getSearchTermValue } = useHakuComponent()
const data = ref<HuonekorttiNimikeWithHuonekorttiResponse[]>([])
const { nimikeKategoriat } = useNimikeKategoria()
const { formatTilat } = useHuonekorttiFormatTilat()
const { selectedNimikkeet, isSelectable } = useNimikeSelection()
const openedDetails = ref<HuonekorttiResponse[]>([])


const setDetails = (row: HuonekorttiNimikeWithHuonekorttiResponse, expanded: boolean) => {
    if (expanded && row.huonekortti) {
        openedDetails.value?.push(row.huonekortti)
    }
    else {
        openedDetails.value = openedDetails.value?.filter(h => h.id !== row.huonekortti?.id)
    }
}
const details = (id: number) => {
    return openedDetails.value?.find((h: HuonekorttiResponse) => h.id === id) 
}
const handleSelectionChange = (selection: HuonekorttiNimikeWithHuonekorttiResponse[]) => {
    selectedNimikkeet.value = selection
}

watch(nimikeKategoriat, (value) => {
    if(value) {
        data.value = nimikkeet.value
    }
}, { immediate: true })

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: data.value,
    onExpandChange: setDetails,
    border: "",
    onSelectionChange: handleSelectionChange
  }, {
    default: _withCtx(() => [
      (_unref(isSelectable))
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 0,
            type: "selection",
            width: "55"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_table_column, { type: "expand" }, {
        default: _withCtx((scope) => [
          (details(scope.row.huonekortti.id))
            ? (_openBlock(), _createBlock(ComHakuResultDetails, {
                key: 0,
                huonekortti: details(scope.row.huonekortti.id)
              }, null, 8, ["huonekortti"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Nimike",
        prop: "nimike.nimi"
      }),
      _createVNode(_component_el_table_column, {
        prop: "tasmennys",
        label: "Huomioitavaa",
        "min-width": "300",
        resizable: ""
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", {
            innerHTML: _unref(markFindCut)(_unref(getSearchTermValue)('tasmennys', scope.row.nimike.id), scope.row.tasmennys, true),
            style: {"margin-left":"10px"}
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Määrä",
        width: "70",
        prop: "maara"
      }),
      _createVNode(_component_el_table_column, { label: "Ominaisuudet" }, {
        default: _withCtx((scope) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.ominaisuudet, (ominaisuus, index) => {
            return (_openBlock(), _createBlock(_component_el_tag, {
              type: "info",
              size: "small",
              style: {"margin-right":"2px"},
              key: index
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(ominaisuus.nimi), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Tilanimike",
        prop: "huonekortti.tilanimike.nimi"
      }, {
        default: _withCtx((scope) => [
          _createVNode(_component_router_link, {
            to: { name: 'Huonekortti', params: { huonekorttiId: scope.row.huonekortti.id } },
            custom: ""
          }, {
            default: _withCtx(({ href, navigate }) => [
              _createVNode(_component_el_link, {
                type: "primary",
                href: href,
                onClick: navigate
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(scope.row.huonekortti.tilanimike.nimi), 1)
                ]),
                _: 2
              }, 1032, ["href", "onClick"])
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "Tilat" }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(_unref(formatTilat)(scope.row.huonekortti.tilat, true)), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}
}

})