import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { HuonekorttiNimikeResponse } from '@/api/models/huonekortti-nimike-response';
import { onUnmounted, PropType } from 'vue';
import { useNimikeMuokkausComponent } from './nimikeMuokkaus';
import ComNimikeMuokkausTable from './ComNimikeMuokkausTable.vue';
import _ from 'lodash';
import ComNimikeMuokkausNimike from './ComNimikeMuokkausNimike.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComNimikeMuokkaus',
  props: {
    nimikeet: {
        type: Object as PropType<HuonekorttiNimikeResponse[]>,
        required: true,
    }
},
  setup(__props) {

const props = __props

const { nimikkeet: _nimikkeet, resetState } = useNimikeMuokkausComponent() 

_nimikkeet.value = _.cloneDeep(props.nimikeet)

onUnmounted(() => {
    resetState()
})


return (_ctx: any,_cache: any) => {
  const _component_el_divider = _resolveComponent("el-divider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ComNimikeMuokkausNimike),
    _createVNode(_component_el_divider, { "border-style": "none" }),
    _createVNode(ComNimikeMuokkausTable)
  ], 64))
}
}

})