import { KayttajaOikeusResponse } from "@/api/models/kayttaja-oikeus-response"
import { Ref, computed } from "vue"
import http from "@/http-common"
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query"
import { OikeusRequest } from "@/api/models/oikeus-request"
import { OikeusProperties } from "@/api/models/oikeus-properties"
import { PaakayttajaResponse } from "@/api/models/paakayttaja-response"
import { KayttajaResponse } from "@/api/models/kayttaja-response"
import { KayttajaRequest } from "@/api/models/kayttaja-request"
import { OikeusResponse } from "@/api/models/oikeus-response"

const oikeudetUrl = "oikeudet"
const paakayttajaUrl = oikeudetUrl + "/paakayttajat/hankeId"
const paakayttajaOikeudetUrl = "oikeudet/paakayttajat"
const paakayttajaOikeudetHankeUrl = paakayttajaOikeudetUrl + "/hankeId"
const sairaalanKayttajatUrl = "kayttajat/hankeId"
const kayttajaUrl = "kayttajat"

export function useVastuuyksikoidenOikeudet(ids : Ref<number[] | undefined>) {
    const key = computed(() => [oikeudetUrl, {vastuuyksikot: ids.value}])
    const enabled = computed(() => ids.value !== undefined && ids.value.length > 0)

    const fetcher = async () : Promise<KayttajaOikeusResponse[]> => {
        return (await http.get(oikeudetUrl, {params: {vastuuyksikot: ids.value?.join(",")}})).data
    }

    const { data: oikeudet, isLoading: loading, error } = useQuery({ queryKey: key, queryFn: fetcher, enabled: enabled})

    return {
        oikeudet,
        loading,
        error,
    }
}

export function useAddOikeus() {
    const queryClient = useQueryClient()
    const { 
        data: response,
        error,
        isPending: loading,
        mutate
    } = useMutation({
        mutationFn: (request: OikeusRequest) : Promise<OikeusResponse> => http.post(oikeudetUrl, request)
    })

    const addOikeus = async (request: OikeusRequest) => {
        mutate(request, { onSuccess: () => queryClient.invalidateQueries({queryKey: [oikeudetUrl]}) })
    }

    const addOikeudet = async (requests: OikeusRequest[]) => {
        requests.forEach(async (request) => {
            mutate(request, { onSuccess: () => queryClient.invalidateQueries({queryKey: [oikeudetUrl]}) })
        })
    }

    return {
        response,
        error,
        loading,
        addOikeus,
        addOikeudet
    }
}

export function useAddKayttaja() {
    const queryClient = useQueryClient()

    const postKayttaja = async (request: KayttajaRequest) => {
        return (await http.post(kayttajaUrl, request)).data
    }
    
    const { 
        data: response,
        error,
        isPending: loading,
        mutate
    } = useMutation({
        mutationFn: postKayttaja
    })

    const addKayttaja = async (request: KayttajaRequest) => {
        mutate(request, { onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [sairaalanKayttajatUrl]})
            queryClient.invalidateQueries({queryKey: [kayttajaUrl]})
            queryClient.invalidateQueries({queryKey: [oikeudetUrl]})
        } })
    }

    return {
        response,
        error,
        loading,
        addKayttaja
    }
}

export function useUpdateKayttaja() {
    const queryClient = useQueryClient()
    const { 
        data: response,
        error,
        isPending: loading,
        mutate
    } = useMutation({
        mutationFn: (request: {id: number, request: KayttajaRequest}) : Promise<KayttajaResponse> => http.put(`${kayttajaUrl}/${request.id}`, request.request), 
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [sairaalanKayttajatUrl]})
            queryClient.invalidateQueries({queryKey: [kayttajaUrl]})
            queryClient.invalidateQueries({queryKey: [oikeudetUrl]})
            queryClient.invalidateQueries({queryKey: [paakayttajaUrl]})
        }
    })

    const updateKayttaja = async (id: number, request: KayttajaRequest) => {
        mutate({id: id, request: request})
    }

    return {
        response,
        error,
        loading,
        updateKayttaja
    }
}

export function useUpdateOikeus() {
    const queryClient = useQueryClient()
    const { 
        data: response,
        error,
        isPending: loading,
        mutate
    } = useMutation({
        mutationFn: (request: {id: number, request: OikeusProperties}) : Promise<OikeusResponse> => http.put(`${oikeudetUrl}/${request.id}`, request.request), 
        onSuccess: () => queryClient.invalidateQueries({queryKey: [oikeudetUrl]})
    })

    const updateOikeus = async (id: number, request: OikeusProperties) => {
        mutate({id: id, request: request})
    }

    return {
        response,
        error,
        loading,
        updateOikeus
    }
}

export function useDeleteOikeus() {
    const queryClient = useQueryClient()
    const { 
        data: response,
        error,
        isPending: loading,
        mutate
    } = useMutation({
        mutationFn: (id: number) => http.delete(`${oikeudetUrl}/${id}`)
    })

    const deleteOikeus = async (id: number) => {
        mutate(id, { onSuccess: () => queryClient.invalidateQueries({queryKey: [oikeudetUrl]}) })
    }
    
    const deleteOikeudet = async (ids: number[]) => {
        ids.forEach(async (id) => {
            mutate(id, { onSuccess: () => queryClient.invalidateQueries({queryKey: [oikeudetUrl]}) })
        })
    }

    return {
        response,
        error,
        loading,
        deleteOikeus,
        deleteOikeudet
    }
}

export function useHankePaakayttajat(id : Ref<number | undefined>) {
    const key = computed(() => [paakayttajaUrl, {hankeId: id.value}])
    const enabled = computed(() => id.value !== undefined)

    const fetcher = async () : Promise<PaakayttajaResponse[]> => {
        return (await http.get(paakayttajaUrl + '/' + id.value)).data
    }

    const { data: paakayttajat, isLoading: loading, error } = useQuery({ queryKey: key, queryFn: fetcher, enabled: enabled})

    return {
        paakayttajat,
        loading,
        error,
    }
}

export function useAddPaakayttaja() {
    const queryClient = useQueryClient()
    const { 
        data: response,
        error,
        isPending: loading,
        mutate
    } = useMutation({
        mutationFn: (request: {hankeId: number, kayttajaId: number}) : Promise<PaakayttajaResponse> => http.post(paakayttajaOikeudetHankeUrl + "/" + request.hankeId, null, {params: {kayttajaId: request.kayttajaId}})
    })

    const addPaakayttaja = async (hankeId: number, kayttajaId: number) => {
        mutate({hankeId: hankeId, kayttajaId: kayttajaId}, {onSuccess: () => queryClient.invalidateQueries({queryKey: [paakayttajaUrl]})})
    }

    const addPaakayttajat = async (hankeId: number, kayttajaIds: number[]) => {
        kayttajaIds.forEach(async (kayttajaId) => {
            mutate({hankeId: hankeId, kayttajaId: kayttajaId}, {onSuccess: () => queryClient.invalidateQueries({queryKey: [paakayttajaUrl]})})
        })
    }

    return {
        response,
        error,
        loading,
        addPaakayttaja,
        addPaakayttajat
    }
}

export function useDeletePaakayttaja() {
    const queryClient = useQueryClient()
    const { 
        data: response,
        error,
        isPending: loading,
        mutate
    } = useMutation({
        mutationFn: (paakayttajaId : number) => http.delete(paakayttajaOikeudetUrl + '/' + paakayttajaId)
    })

    const deletePaakayttaja = async (paakayttajaId : number) => {
        mutate(paakayttajaId, {onSuccess: () => queryClient.invalidateQueries({queryKey: [paakayttajaUrl]})})
    }

    const deletePaakayttajat = async (paakayttajaIds: number[]) => {
        paakayttajaIds.forEach(async (paakayttajaId) => {
            mutate(paakayttajaId, {onSuccess: () => queryClient.invalidateQueries({queryKey: [paakayttajaUrl]})})
        })
    }

    return {
        response,
        error,
        loading,
        deletePaakayttaja,
        deletePaakayttajat
    }
}

export function useKayttajat(email? : Ref<string>, page? : Ref<number>) {
    const key = computed(() => {
        const params = Object.assign({}, email?.value ? {email: email.value} : null, page?.value ? {page: page.value} : null)

        return [kayttajaUrl, params]
    })

    const fetcher = async () : Promise<KayttajaResponse[]> => {
        return (await http.get(kayttajaUrl, {params: key.value[1]})).data
    }

    const { data: kayttajat, isPending: pending, isLoading: loading, error } = useQuery({ queryKey: key, queryFn: fetcher})

    return {
        kayttajat,
        loading,
        error,
        pending
    }
}

export async function getKayttajat(email?: string, page?: number) : Promise<KayttajaResponse[] | undefined> {
    const params = new URLSearchParams()
    const base = "kayttajat"

    if (email) {
        params.set("email", email)
    }
    if (page) {
        params.set("page", page.toString())
    }

    const url = base + "?" + params.toString()

    const response = await http.get(url)

    if(response) {
        return response.data
    }
}

export function useSairaalaKayttajatByHanke(id : Ref<number>) {
    const key = computed(() => [sairaalanKayttajatUrl, {hankeId: id.value}])
    const enabled = computed(() => id.value !== undefined)

    const fetcher = async () : Promise<KayttajaResponse[]> => {
        return (await http.get(sairaalanKayttajatUrl + '/' + id.value)).data
    }

    const { data: kayttajat, isLoading: loading, error } = useQuery({ queryKey: key, queryFn: fetcher, enabled: enabled})

    return {
        kayttajat,
        loading,
        error,
    }
}