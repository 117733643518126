<template>
    <ComLoading v-if="!nimikkeet" />
    <div v-else class="card-margin-bottom">
        <el-row>
            <el-col>
                <el-cascader-panel 
                :options="nimikkeet" 
                :props="{
                    disabled: false,
                    multiple: true,
                }" 
                v-model="valitutNimikkeet" />
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <el-checkbox
                    label="Valitse erikseen raportoitavat huonekortit"
                    v-model="huonekorteittain"></el-checkbox>
            </el-col>
        </el-row>
        <el-divider v-if="huonekorteittain" />
        <el-row v-if="huonekorteittain">
            <el-col :span="24">
                <ComHuonekorttiTableWithFilterAndPagination :selection="true" />
            </el-col>
        </el-row>
    </div>
</template>

<script setup lang="ts">
import ComLoading from "@/components/common/ComLoading.vue"
import ComHuonekorttiTableWithFilterAndPagination from "@/components/hanke/huonekortit/table/ComHuonekorttiTableWithFilterAndPagination.vue"
import { setNodes, useNimikeKategoria } from "@/methods/nimikkeetComposables";
import { ref, toRef, watch, watchEffect } from "vue"

const emit = defineEmits(["huonekorteittain", "nimikkeet"])
const { nimikeKategoriat } = useNimikeKategoria(toRef(true))
const nimikkeet = ref()
const valitutNimikkeet = ref<[number[]]>()
const huonekorteittain = ref(false)

watch(valitutNimikkeet, () => {
    let n: number[] = []

    if (valitutNimikkeet.value) {
        n = valitutNimikkeet.value?.map((v: number[]) => {
            return v[2]
        })
    }

    emit("nimikkeet", n)
})

watch(huonekorteittain, () => {
    emit("huonekorteittain", huonekorteittain.value)
})

watchEffect(() => {
    nimikkeet.value = setNodes(nimikeKategoriat.value ?? [], true)
})

</script>
