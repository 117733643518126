<template>
    <el-row>
        <el-col :span="6">
            <ComFilterInput v-model="filter" />
        </el-col>
        <el-col :span="18">
            <ComPagination
                :total="total"
                v-model:page-size="pageSize"
                v-model:current-page="currentPage"
                style="float: right" />
        </el-col>
    </el-row>
    <ComHuonekorttiTable :selection="true" :huonekortit="huonekortit"></ComHuonekorttiTable>
</template>

<script setup lang="ts">
import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response"
import ComFilterInput from "@/components/common/ComFilterInput.vue"
import ComPagination from "@/components/common/ComPagination.vue"
import { PropType, watchEffect } from "vue"
import ComHuonekorttiTable from "./ComHuonekorttiTable.vue"
import { useHuonekortit } from "@/methods/huonekortitComposables"
import { hankePage } from "@/views/hanke"

const props = defineProps({
    status: {
        type: Array as PropType<Array<HuonekorttiStatusResponse>>,
        default: () => {
            return [
                HuonekorttiStatusResponse.Julkaistu,
                HuonekorttiStatusResponse.Odottaa,
                HuonekorttiStatusResponse.Hyvaksytty,
            ]
        },
    },
    selection: {
        type: Boolean,
        default: false,
    },

})

const { huonekortit, total, filter, currentPage, paramsObject, pageSize, status : _status } = useHuonekortit()
const { selectedVastuuyksikkoIds } = hankePage()

watchEffect(() => paramsObject.value.vastuuyksikot = selectedVastuuyksikkoIds.value)
watchEffect(() => _status.value = props.status)
</script>
