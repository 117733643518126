<template>
    <div class="card-margin-bottom">
        <el-form :inline="true" label-position="top" :rules="rules" :model="nimikeForm" ref="nimikeFormRef">
            <el-col :lg="5" :span="24">
                <el-form-item label="Nimike" prop="nimike">
                    <el-cascader
                        filterable
                        clearable
                        @change="ominaisuudetChecked = []"
                        :options="nodes"
                        placeholder="Hae kirjoittamalla"
                        :filter-method="filter"
                        size="small"
                        v-model="nimikeForm.nimike.id"
                        style="width: 100%" />
                </el-form-item>
            </el-col>
            <el-col :lg="5" :span="24">
                <el-form-item label="Ominaisuudet">
                    <el-select
                        multiple
                        v-model="ominaisuudetChecked"
                        placeholder="Valitse ominaisuudet"
                        style="width: 100%"
                        size="small"
                        value-key="id">
                        <el-option
                            v-for="(ominaisuus, index) in ominaisuudet"
                            :key="index"
                            :label="ominaisuus.nimi"
                            :value="ominaisuus"/>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :lg="3" :span="24">
                <el-form-item label="Kpl" prop="maara">
                    <el-input-number :min="0" size="small" v-model="nimikeForm.maara" style="width: 100%" />
                </el-form-item>
            </el-col>
            <el-col :lg="7" :span="24">
                <el-form-item label="Täsmennys">
                    <el-input v-model="nimikeForm.tasmennys" type="textarea" autosize style="width: 100%"></el-input>
                </el-form-item>
            </el-col>
            <el-col :lg="4" :span="24">
                <el-form-item label="&nbsp;">
                    <el-button type="success" @click="add" size="small">Lisää nimike</el-button>
                </el-form-item>
            </el-col>
        </el-form>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import { HuonekorttiNimikeRequest } from "@/api/models/huonekortti-nimike-request"
import { useNimikeAdd } from "@/methods/huonekorttiComposablses"
import { NimikeResponse } from "@/api/models/nimike-response"
import { OminaisuusResponse } from "@/api/models/ominaisuus-response"
import NimikeForm from "@/types/NimikeForm"
import { huonekorttiPage } from "@/views/huonekortti"
import { ElMessage, ElNotification } from "element-plus"
import { setNodes, filter } from "@/methods/nimikkeetComposables"

const { addNimike, loading, error, response } = useNimikeAdd()
const { nimikeKategoriat, huonekortti, setEdited } = huonekorttiPage()
const valittuKategoria = computed(() => nimikeKategoriat.value?.find((k) => k.id === nimikeForm.value.nimike.id?.[0]))
const valittuAlakategoria = computed(() =>
    valittuKategoria.value?.children?.find((a) => a.id === nimikeForm.value.nimike.id?.[1])
)
const valittuNimike = computed(
    () =>
        valittuAlakategoria.value?.nimikkeet?.find((n) => n.id === nimikeForm.value.nimike.id?.[2]) as
            | NimikeResponse
            | undefined
)
const ominaisuudet = computed(() => valittuNimike.value?.ominaisuudet?.filter((o) => o.kaytossa === true) ?? [])
const ominaisuudetChecked = ref<OminaisuusResponse[]>([])
const nimikeFormRef = ref()
const nimikeForm = ref<NimikeForm>({
    nimike: {
        id: undefined,
    },
    maara: undefined,
    tasmennys: "",
})
const rules = ref({
    nimike: [
        {
            type: "object",
            required: true,
            trigger: "blur",
            fields: {
                id: {
                    type: "array",
                    length: 3,
                    required: true,
                    message: "Nimike tulee olla valittu.",
                },
            },
        },
    ],
    maara: [
        {
            type: "integer",
            trigger: "blur",
            required: true,
            min: 0,
            message: "Kappalemäärä tulee olla 0 tai enemmän.",
        },
    ],
})
const nodes = computed(() => {
    return setNodes(nimikeKategoriat.value ?? [])
})
const ohje = computed(() => {
    let message = ""

    const o = [
        {ohje: valittuKategoria.value?.ohje, nimi: valittuKategoria.value?.nimi},
        {ohje: valittuAlakategoria.value?.ohje, nimi: valittuAlakategoria.value?.nimi},
        {ohje: valittuNimike.value?.ohje, nimi: valittuNimike.value?.nimi},
        ...ominaisuudetChecked.value.map((i) => {
            return {ohje: i.ohje, nimi: i.nimi}
        })
    ].filter((o) => o.ohje)

    if (o.length !== 0) {
        message = "<ul>"
        message = message + o.map((m) => `<li>${m.ohje} (${m.nimi})</li>`).join("")
        message = message + "</ul>"

        return message
    } else {
        return false
    }
})
const notification = ref()

const add = async () => {
    nimikeFormRef.value.validate(async (valid: boolean) => {
        if (!valid) { 
            return false
        } else if (huonekortti.value?.id){
            const nimike = {
                nimike: {
                    id: valittuNimike.value?.id,
                },
                maara: nimikeForm.value.maara,
                tasmennys: nimikeForm.value.tasmennys,
                ominaisuudet: ominaisuudetChecked.value.map((o) => {
                    return { id: o.id }
                }),
            } as HuonekorttiNimikeRequest

            addNimike(huonekortti.value?.id, nimike)
        }
    })
}
const resetFields = () => {
    nimikeForm.value.maara = undefined
    nimikeForm.value.tasmennys = ""
    nimikeForm.value.nimike = {
        id: undefined,
    }
    ominaisuudetChecked.value = []
}

watch(loading, (val) => {
    if (!val && response.value) {
        ElMessage.success("Nimike lisätty")

        resetFields()
    }
})
watch(error, (val) => {
    if (val) {
        ElMessage.error("Nimikkeen lisäys epäonnistui")
    }
})
watch(
    () => nimikeForm,
    () => {
        if (nimikeForm.value.maara || nimikeForm.value.nimike.id || nimikeForm.value.tasmennys) {
            setEdited("nimikeLisays", true)
        } else {
            setEdited("nimikeLisays", false)
        }
    },
    { deep: true }
)
watch(ohje, () => {
    if(notification.value) {
        notification.value.close()
    }

    if (ohje.value) {
        notification.value = ElNotification({
            title: "Huomioitavaa",
            message: ohje.value,
            type: "warning",
            duration: 0,
            dangerouslyUseHTMLString: true,
            position: "top-left",
            customClass: "nimike-huomio"
        })
    }
})

</script>
