import { HuonekorttiNimikeRequestPut } from "@/api/models/huonekortti-nimike-request-put";
import { HuonekorttiNimikeResponse } from "@/api/models/huonekortti-nimike-response";
import { useNimikeetUpdate } from "@/methods/huonekorttiComposablses";
import _ from "lodash";
import { ref, watch } from "vue";
import { useHakuComponent } from "../haku";
import { ElMessage } from "element-plus";
import { useNimikeSelection } from "../nimikeSelection";
import { OminaisuusResponse } from "@/api/models/ominaisuus-response";

const nimikkeet = ref<HuonekorttiNimikeResponse[]>([])
const nimikeetRequest = ref<{ id: number, nimike:HuonekorttiNimikeRequestPut}[]>([])
const loading = ref<boolean>(false)
const updateSuccess = ref<number[]>([])
const showLisaaOminaisuudet = ref(false)

export function useNimikeMuokkausComponent() {
    const editedNimike = ref<HuonekorttiNimikeResponse>()
    const editedId = ref<number>()
    const nimikeId = ref<number>()
    const { responses: updateReponses, errors: updateErrors, loading: updateNimikkeetLoading, updateNimikeet } = useNimikeetUpdate()
    const { nimikkeetLoading, hae } = useHakuComponent()
    const { nimikeEditModalVisible } = useNimikeSelection()

    const muokkaaNimiketta = (nimike: HuonekorttiNimikeResponse) => {
        editedNimike.value = _.cloneDeep(nimike)
        editedId.value = nimike.id
    }
    const peruutaMuokkaus = () => {
        editedNimike.value = undefined
        editedId.value = undefined
    }
    const tallennaMuokkaus = () => {
        const nimike = nimikkeet.value.find(n => n.id == editedId.value)

        if(nimike) {
           nimike.maara = editedNimike.value?.maara
           nimike.ominaisuudet = editedNimike.value?.ominaisuudet
           nimike.tasmennys = editedNimike.value?.tasmennys 
        }

        peruutaMuokkaus()
    }
    const overwriteNimikkeet = (nimike: HuonekorttiNimikeResponse) => {
        if(!nimike.maara || nimike.maara <= 0) {
            ElMessage.warning("Nimikkeen määrä tulee olla positiivinen luku")
            
            return
        }

        nimikkeet.value.forEach(n => {
            n.maara = nimike.maara
            n.ominaisuudet = nimike.ominaisuudet
            n.tasmennys = nimike.tasmennys
        })
    }
    const tallennaNimikkeet = () => {
        loading.value = true

        nimikeetRequest.value = nimikkeet.value.map(n => {
            return {
                id: n.id,
                nimike: {
                    maara: n.maara,
                    ominaisuudet: n.ominaisuudet,
                    tasmennys: n.tasmennys
                }
            }
        })

        updateNimikeet(nimikeetRequest.value)
    }
    const addOminaisuudet = (value: OminaisuusResponse[]) => {
        if(!value || value.length == 0) {
            ElMessage.warning("Valitse vähintään yksi ominaisuus")
            
            return
        }

        nimikkeet.value.forEach(n => {
            if(!n.ominaisuudet) {
                n.ominaisuudet = []
            }

            n.ominaisuudet = n.ominaisuudet.concat(value)
            n.ominaisuudet = _.uniqBy(n.ominaisuudet, o => o.id)
        })

        showLisaaOminaisuudet.value = false
    }
    const resetState = () => {
        nimikkeet.value = []
        nimikeetRequest.value = []
        loading.value = false
        updateSuccess.value = []
    }

    watch(nimikkeet, (value) => {
        if(value && value.length > 0) {
            nimikeId.value = value[0].nimike?.id
        }
    }, { immediate: true })
    watch(updateNimikkeetLoading, (value) => {
        if(updateErrors.value.length > 0) {
            loading.value = false
            ElMessage.error("Vähintään yhden nimikkeen päivitys epäonnistui")

            return
        }
        if(!value) {
            hae()
        }
        
    }, { deep: true })
    watch(nimikkeetLoading, (value) => {
        if(nimikkeet.value.length > 0 && !value) {
            loading.value = false
            ElMessage.success("Nimikkeet päivitetty")

            setTimeout(() => {
                nimikeEditModalVisible.value = false
            }, 2000)
        }
    })
    watch(updateReponses, (value) => {
        if(value.length > 0) {
            updateSuccess.value = value.map(v => v.id)
        }
    }, { deep: true })
    
    return {
        nimikkeet,
        editedNimike,
        editedId,
        nimikeId,
        updateReponses,
        loading,
        updateErrors,
        updateSuccess,
        showLisaaOminaisuudet,
        resetState,
        muokkaaNimiketta,
        peruutaMuokkaus,
        tallennaMuokkaus,
        overwriteNimikkeet,
        tallennaNimikkeet,
        addOminaisuudet
    }
}