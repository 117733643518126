<template>
    <el-card class="el-card-blank" shadow="never">
        <template #header>
            Muokkaa kaikkia alla olevia nimikkeitä
        </template>
        <el-form :inline="true" label-position="top">
            <el-col :lg="5" :span="24">
                <el-form-item label="Nimike" prop="nimike">
                    {{ nimike?.nimi }}
                </el-form-item>
            </el-col>
            <el-col :lg="5" :span="24">
                <el-form-item label="Ominaisuudet">
                    <el-select
                        v-if="nimike"
                        multiple
                        v-model="huonekorttiNimike.ominaisuudet"
                        placeholder="Valitse ominaisuudet"
                        style="width: 100%"
                        size="small"
                        value-key="id">
                        <el-option
                            v-for="(ominaisuus, index) in nimike.ominaisuudet"
                            :key="index"
                            :label="ominaisuus.nimi"
                            :value="ominaisuus"/>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :lg="3" :span="24">
                <el-form-item label="Kpl" prop="maara">
                    <el-input-number :min="0" size="small" v-model="huonekorttiNimike.maara" style="width: 100%" />
                </el-form-item>
            </el-col>
            <el-col :lg="7" :span="24">
                <el-form-item label="Täsmennys">
                    <el-input v-model="huonekorttiNimike.tasmennys" type="textarea" autosize style="width: 100%"></el-input>
                </el-form-item>
            </el-col>
            <el-col :lg="4" :span="24">
                <el-form-item label="&nbsp;">
                    <el-button type="success" @click="overwriteNimikkeet(huonekorttiNimike)" size="small">Ylikirjoita</el-button>
                </el-form-item>
            </el-col>
        </el-form>
    </el-card>
</template>

<script setup lang="ts">
import { useNimike } from '@/methods/nimikkeetComposables';
import { useNimikeMuokkausComponent } from './nimikeMuokkaus';
import { ref } from 'vue';
import { HuonekorttiNimikeResponse } from '@/api/models/huonekortti-nimike-response';

const huonekorttiNimike = ref<HuonekorttiNimikeResponse>({} as HuonekorttiNimikeResponse)
const { nimikeId, overwriteNimikkeet } = useNimikeMuokkausComponent()
const { nimike } = useNimike(nimikeId)

</script>