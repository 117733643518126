import { HuonekorttiNimikeWithHuonekorttiResponse } from "@/api/models/huonekortti-nimike-with-huonekortti-response";
import { computed, ref, watch } from "vue";
import { useHakuComponent } from "./haku";
import { hankePage } from "@/views/hanke";
import { ElMessage } from "element-plus";

const selectedNimikkeet = ref<HuonekorttiNimikeWithHuonekorttiResponse[]>([])
const nimikeEditModalVisible = ref(false)
const isSelectable = ref(false)

export function useNimikeSelection() {
    const { nimikkeet, searchType, searchTotal } = useHakuComponent()
    const { paakayttaja } = hankePage()
    const showMuokkaa = computed(() => {
        return searchType.value == 3 && isSelectable.value && paakayttaja.value && searchTotal.value > 0
    })

    const showNimikeEditModal = () => {
        if(selectedNimikkeet.value.length == 0) {
            ElMessage.warning("Valitse ensin nimikeet, joita haluat muokata")
            
            return
        }
        nimikeEditModalVisible.value = true
    }
    const hideNimikeEditModal = () => {
        nimikeEditModalVisible.value = false
        selectedNimikkeet.value = []
    }

    watch(nimikkeet, (value) => {
        if(value.some(n => n.nimike?.id != value[0].nimike?.id)) {
            isSelectable.value = false
        } else {
            isSelectable.value = true
        }
        
        selectedNimikkeet.value = []
    }, { immediate: true })

    return {
        selectedNimikkeet,
        nimikeEditModalVisible,
        showMuokkaa,
        isSelectable,
        showNimikeEditModal,
        hideNimikeEditModal
    }
}