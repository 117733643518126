import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

import { HuonekorttiNimikeResponse } from '@/api/models/huonekortti-nimike-response';
import { useNimikeKategoria } from '@/methods/nimikkeetComposables';
import { ref, watch } from 'vue';
import { useHakuComponent } from './haku';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHakuResultDetailsNimikeTable',
  props: {
    nimikeet: {}
  },
  setup(__props: any) {

const props = __props
const data = ref<HuonekorttiNimikeResponse[]>()
const { nimikeKategoriat } = useNimikeKategoria()
const { setKategoria, searchForm } = useHakuComponent()

const kategoria = (id: number) => {
    if(nimikeKategoriat.value) {
        return setKategoria(id, nimikeKategoriat.value)
    }
    else {
        return ''
    }
}

watch(nimikeKategoriat, (value) => {
    const nimikkeet = searchForm.value.nimike

    if(value && nimikkeet.length > 0) {
        data.value = props.nimikeet.filter(nimike => nimikkeet.find(n => n.id === nimike.nimike?.id))
    }
}, { immediate: true })

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, { data: data.value }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, { label: "Kategoria" }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(kategoria(scope.row.nimike.kategoria.id)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Nimike",
        prop: "nimike.nimi"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.nimike.nimi), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Huomioitavaa",
        prop: "tasmennys"
      }),
      _createVNode(_component_el_table_column, {
        label: "Määrä",
        width: "100",
        prop: "maara"
      }),
      _createVNode(_component_el_table_column, { label: "Ominaisuudet" }, {
        default: _withCtx((scope) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.ominaisuudet, (ominaisuus, index) => {
            return (_openBlock(), _createBlock(_component_el_tag, {
              type: "info",
              size: "small",
              style: {"margin-right":"2px"},
              key: index
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(ominaisuus.nimi), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}
}

})