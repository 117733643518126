<template>
    <el-table :data="tilat" border @expand-change="setDetails">
        <el-table-column type="expand">
            <template #default="scope">
                <template v-if="details(scope.row.huonekortti.id)">
                    <ComHakuResultDetails :huonekortti="details(scope.row.huonekortti.id)" />
                </template>
            </template>
        </el-table-column>
        <el-table-column
            prop="tilatunnus"
            label="Tilatunnus"
            width="180">
            <template #default="scope">
                <router-link
                    :to="{ name: 'Huonekortti', params: { huonekorttiId: scope.row.huonekortti.id } }"
                    v-slot="{ href, navigate }"
                    custom>
                    <el-link type="primary" :href="href" @click="navigate">{{ scope.row.tilatunnus }}</el-link>
                </router-link>
            </template>
        </el-table-column>
        <el-table-column label="Pinta-ala" prop="pintaAla"/>
        <el-table-column label="Tilanimike" prop="huonekortti.tilanimike.nimi"/>
        <el-table-column
            label="Täsmennys"
            min-width="250px">
            <template #default="scope">
                <span v-html="markFindCut(getSearchTermValue('tilanimike-tasmennys'), scope.row.huonekortti.toiminta ?? '', false)" style="margin-left: 10px"></span>
            </template>
        </el-table-column>
        <el-table-column label="Vastuuyksikkö" prop="huonekortti.vastuuyksikko.nimi"/>
    </el-table>
</template>

<script lang="ts" setup>
import { HuonekorttiResponse } from '@/api/models/huonekortti-response';
import { useHakuComponent } from './haku';
import { ref } from 'vue';
import ComHakuResultDetails from './ComHakuResultDetails.vue';
import { TilaResponseExtended } from '@/api/models/tila-response-extended';

const { tilat, markFindCut, getSearchTermValue } = useHakuComponent()
const openedDetails = ref<HuonekorttiResponse[]>([])

const setDetails = (row: TilaResponseExtended, expanded: boolean) => {
    if (expanded && row.huonekortti) {
        openedDetails.value?.push(row.huonekortti)
    }
    else {
        openedDetails.value = openedDetails.value?.filter(h => h.id !== row.huonekortti?.id)
    }
}

const details = (id: number) => {
    return openedDetails.value?.find((h: HuonekorttiResponse) => h.id === id) 
}
</script>