import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, isRef as _isRef, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import ComLisaaHuonekortti from "./ComLisaaHuonekortti.vue"
import ComFilterInput from "../../common/ComFilterInput.vue"
import ComHuonekorttiTableEditable from "./table/ComHuonekorttiTableEditable.vue"
import { huonekorttiListing } from "./huonekortit"
import ComLoading from "@/components/common/ComLoading.vue"
import ComPagination from "@/components/common/ComPagination.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHuonekortit',
  setup(__props) {

const { writableVastuuyksikot, filter, currentPage, pageSize, total, loading, vastuuyksikkoSelected, hankeId } = huonekorttiListing()


return (_ctx: any,_cache: any) => {
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(writableVastuuyksikot)?.length)
      ? (_openBlock(), _createBlock(_component_el_card, {
          key: 0,
          class: "el-card-blank",
          shadow: "never"
        }, {
          header: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Huonekortin lisäys ")
          ])),
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_KeepAlive, null, [
              (_openBlock(), _createBlock(ComLisaaHuonekortti, { key: _unref(hankeId) }))
            ], 1024))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_el_divider, { "border-style": "none" }),
    _withDirectives(_createVNode(ComLoading, null, null, 512), [
      [_vShow, _unref(loading)]
    ]),
    _withDirectives(_createVNode(_component_el_empty, { description: "Valitse vähintään yksi vastuuyksikkö!" }, null, 512), [
      [_vShow, !_unref(vastuuyksikkoSelected)]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 6 }, {
            default: _withCtx(() => [
              _createVNode(ComFilterInput, {
                modelValue: _unref(filter),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(filter) ? (filter).value = $event : null))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 18 }, {
            default: _withCtx(() => [
              _createVNode(ComPagination, {
                total: _unref(total),
                "page-size": _unref(pageSize),
                "onUpdate:pageSize": _cache[1] || (_cache[1] = ($event: any) => (_isRef(pageSize) ? (pageSize).value = $event : null)),
                "current-page": _unref(currentPage),
                "onUpdate:currentPage": _cache[2] || (_cache[2] = ($event: any) => (_isRef(currentPage) ? (currentPage).value = $event : null)),
                style: {"float":"right"}
              }, null, 8, ["total", "page-size", "current-page"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(ComHuonekorttiTableEditable)
    ], 512), [
      [_vShow, !_unref(loading) && _unref(vastuuyksikkoSelected)]
    ])
  ], 64))
}
}

})