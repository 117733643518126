import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"padding":"0px 20px"}
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

import { useHuonekorttiFormatTilat } from '@/methods/huonekorttiComposablses';
import { useHakuComponent } from './haku';
import { HuonekorttiResponse } from '@/api/models/huonekortti-response';
import { computed, ref, watch } from 'vue';
import ComHakuResultDetailsNimikeTable from './ComHakuResultDetailsNimikeTable.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHakuResultDetails',
  props: {
    huonekortti: {}
  },
  setup(__props: any) {

const props = __props

const { markFindCut, getSearchTermValue, searchForm } = useHakuComponent()
const { formatTilat } = useHuonekorttiFormatTilat()
const _huonekortti = ref<HuonekorttiResponse | undefined>()
const nimikkeet = computed(() => props.huonekortti?.nimikkeet != undefined && searchForm.value.nimike?.length > 0)

watch(() => props.huonekortti, (newValue) => {
    _huonekortti.value = newValue
})


return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!

  return (_ctx.huonekortti)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_row, {
          gutter: 20,
          style: {"padding-bottom":"10px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _cache[0] || (_cache[0] = _createElementVNode("h4", null, "Toiminnan kuvaus", -1)),
                _createElementVNode("div", {
                  innerHTML: _unref(markFindCut)(_unref(getSearchTermValue)('toiminnan-kuvaus'), _ctx.huonekortti.toiminnanKuvaus ?? '', true),
                  style: {"max-height":"300px","overflow-y":"scroll"}
                }, null, 8, _hoisted_2)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _cache[1] || (_cache[1] = _createElementVNode("h4", null, "Toiminnan vaatimukset", -1)),
                _createElementVNode("div", {
                  innerHTML: _unref(markFindCut)(_unref(getSearchTermValue)('toiminnan-vaatimukset'), _ctx.huonekortti.toiminnanVaatimukset ?? '', true),
                  style: {"max-height":"300px","overflow-y":"scroll"}
                }, null, 8, _hoisted_3)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions, {
          border: "",
          column: 4
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_descriptions_item, { label: "Tilanimike" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.huonekortti.tilanimike?.nimi), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_descriptions_item, { label: "Täsmennys" }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  innerHTML: _unref(markFindCut)(_unref(getSearchTermValue)('tilanimike-tasmennys'), _ctx.huonekortti.toiminta ?? '', false)
                }, null, 8, _hoisted_4)
              ]),
              _: 1
            }),
            _createVNode(_component_el_descriptions_item, { label: "Vastuuyksikkö" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.huonekortti.vastuuyksikko.nimi), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_descriptions_item, { label: "Tilat" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(formatTilat)(_ctx.huonekortti.tilat ?? [], true)), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_descriptions, {
          border: "",
          column: 4
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_descriptions_item, { label: "Henkilömäärä normaalisti" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.huonekortti.henkilomaaraNorm), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_descriptions_item, { label: "Henkilömäärä maksimi" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.huonekortti.henkilomaaraMax), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_descriptions_item, { label: "Sähköluokka" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.huonekortti.sahkoluokka), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_descriptions_item, { label: "Toiminta-aika" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.huonekortti.toimintaAika), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (nimikkeet.value && _ctx.huonekortti.nimikkeet)
          ? (_openBlock(), _createBlock(ComHakuResultDetailsNimikeTable, {
              key: 0,
              nimikeet: _ctx.huonekortti.nimikkeet
            }, null, 8, ["nimikeet"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})