<template>
    <el-table :data="data">
        <el-table-column label="Kategoria">
            <template #default="scope">
                {{ kategoria(scope.row.nimike.kategoria.id) }}
            </template>
        </el-table-column>
        <el-table-column label="Nimike" prop="nimike.nimi">
            <template #default="scope">
                {{ scope.row.nimike.nimi }}
            </template>
        </el-table-column>
        <el-table-column label="Huomioitavaa" prop="tasmennys"/>
        <el-table-column label="Määrä" width="100" prop="maara"/>
        <el-table-column label="Ominaisuudet">
            <template #default="scope">
                <el-tag
                    type="info"
                    size="small"
                    style="margin-right: 2px"
                    v-for="(ominaisuus, index) in scope.row.ominaisuudet"
                    :key="index">
                    <span> {{ ominaisuus.nimi }} </span>
                </el-tag>
            </template>
        </el-table-column>
    </el-table>
</template>


<script setup lang="ts">
import { HuonekorttiNimikeResponse } from '@/api/models/huonekortti-nimike-response';
import { useNimikeKategoria } from '@/methods/nimikkeetComposables';
import { ref, watch } from 'vue';
import { useHakuComponent } from './haku';

const props = defineProps<{
    nimikeet: HuonekorttiNimikeResponse[]
}>()
const data = ref<HuonekorttiNimikeResponse[]>()
const { nimikeKategoriat } = useNimikeKategoria()
const { setKategoria, searchForm } = useHakuComponent()

const kategoria = (id: number) => {
    if(nimikeKategoriat.value) {
        return setKategoria(id, nimikeKategoriat.value)
    }
    else {
        return ''
    }
}

watch(nimikeKategoriat, (value) => {
    const nimikkeet = searchForm.value.nimike

    if(value && nimikkeet.length > 0) {
        data.value = props.nimikeet.filter(nimike => nimikkeet.find(n => n.id === nimike.nimike?.id))
    }
}, { immediate: true })
</script>