import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {  watch, ref,  computed, toRef } from "vue"
import { useVModel } from "@vueuse/core"
import { MallihuonekorttiRequestPut } from "@/api/models/mallihuonekortti-request-put"
import { ElMessage } from "element-plus"
import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { useAddMallihuonekortti, useUpdateMallihuonekortti } from "@/methods/mallihuonekortitComposables"
import ComSuccessButton from "@/components/common/buttons/ComSuccessButton.vue"
import ComCancelButton from "@/components/common/buttons/ComCancelButton.vue"
import { useHankeDataByVastuuyksikko } from "@/methods/hankeComposables"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComMallihuonekorttiEditToimintaDialog',
  props: {
    huonekortti: {},
    dialog: { type: Boolean }
  },
  emits: ['update:dialog'],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const { hanke } = useHankeDataByVastuuyksikko(toRef(props.huonekortti.vastuuyksikko.id))
const sairaalaId = computed(() => hanke.value?.sairaala?.id)
const toiminta = ref('')
const huonekorttiId = computed(( ) => { 
    return { id: props.huonekortti.id }
})
const show = useVModel(props, 'dialog', emit)
const { addMallihuonekortti, error: addError, response: addResponse } = useAddMallihuonekortti()
const { updateMallihuonekortti, error: updateError, response: updateResponse  } = useUpdateMallihuonekortti()

const save = async () => {
    if(!sairaalaId.value) {
        ElMessage.error("Mallihuonekortin lisäys epäonnistui")
        return
    }

    addMallihuonekortti(huonekorttiId.value, sairaalaId.value)
}

watch(addResponse, (value) => {
    if(value) {
        const request: MallihuonekorttiRequestPut = { ...value, toiminta: toiminta.value }

        updateMallihuonekortti(value.id, request)
    }
})
watch(updateResponse, (value) => {
    if(value) {
        ElMessage.success("Mallihuonekortti lisätty")
        show.value = false
    }
})
watch(addError, (value) => {
    if(value) {
        ElMessage.error("Mallihuonekortin lisäys epäonnistui")
    }
})
watch(updateError, (value) => {
    if(value) {
        ElMessage.error("Mallihuonekortin päivitys epäonnistui")
    }
})
watch(()=> props.huonekortti, (value) => {
    toiminta.value = value.toiminta ?? ''
})

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _unref(show),
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(show) ? (show).value = $event : null)),
    close: "close",
    width: "30%",
    title: "Haluatko luoda huonekortista mallihuonekortin hyvinvointialueen hankkeisiin?",
    "append-to-body": ""
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_el_space, null, {
        default: _withCtx(() => [
          _createVNode(ComSuccessButton, { onClick: save }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Ok")
            ])),
            _: 1
          }),
          _createVNode(ComCancelButton, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (show.value = false))
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Peruuta")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Täsmennys" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: toiminta.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((toiminta).value = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})