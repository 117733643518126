import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","margin-bottom":"15px"} }
const _hoisted_2 = { style: {"flex":"5 3 75%","min-width":"0"} }
const _hoisted_3 = { class: "text-header content-header" }
const _hoisted_4 = { class: "sub-title" }
const _hoisted_5 = { style: {"flex-grow":"1"} }

import router from '@/router'
import { ArrowLeft } from '@element-plus/icons-vue'
import ComTooltip from '../common/ComTooltip.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ComPageHeader',
  props: {
    contentTitle: String,
    contentSubtitle: String
},
  setup(__props) {



const goBack = () => {
    router.go(-1);
}


return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_el_button, {
        style: {"color":"#303133"},
        text: "",
        onClick: goBack
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_unref(ArrowLeft))
            ]),
            _: 1
          }),
          _cache[0] || (_cache[0] = _createTextVNode(" Takaisin "))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(__props.contentTitle), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(ComTooltip, {
          content: __props.contentSubtitle ?? ''
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(__props.contentSubtitle), 1)
          ]),
          _: 1
        }, 8, ["content"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})