import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

import { ref, watch } from 'vue';
import { useHakuComponent } from './haku';
import { Operator } from '@/api/models/operator';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHakuTilat',
  setup(__props) {

const { searchForm, isSelected, showHeading } = useHakuComponent()
const tila = ref(searchForm.value.tila)

watch(() => searchForm.value.tila, (value) => {
    tila.value = value
}, { immediate: true })


return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(showHeading)(2))
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, "Tila"))
      : _createCommentVNode("", true),
    _createVNode(_component_el_form, { model: tila.value }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, { gutter: 20 }, {
          default: _withCtx(() => [
            (_unref(isSelected)('tilatunnus'))
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 0,
                  span: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "Tilatunnus" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: tila.value.tilatunnus,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tila.value.tilatunnus) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(isSelected)('pinta-ala'))
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 1,
                  span: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "Pinta-ala" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, { span: 12 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              type: "number",
                              modelValue: tila.value['pinta-ala']['pinta-ala'],
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((tila.value['pinta-ala']['pinta-ala']) = $event))
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_col, { span: 12 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_radio_group, {
                              modelValue: tila.value['pinta-ala'].operator,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((tila.value['pinta-ala'].operator) = $event)),
                              size: "small"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_radio_button, {
                                  label: "Yhtä kuin",
                                  value: _unref(Operator).Eq
                                }, null, 8, ["value"]),
                                _createVNode(_component_el_radio_button, {
                                  label: "Pienempi kuin",
                                  value: _unref(Operator).Lt
                                }, null, 8, ["value"]),
                                _createVNode(_component_el_radio_button, {
                                  label: "Isompi kuin",
                                  value: _unref(Operator).Gt
                                }, null, 8, ["value"])
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ], 64))
}
}

})