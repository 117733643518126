import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response"
import ComFilterInput from "@/components/common/ComFilterInput.vue"
import ComPagination from "@/components/common/ComPagination.vue"
import { PropType, watchEffect } from "vue"
import ComHuonekorttiTable from "./ComHuonekorttiTable.vue"
import { useHuonekortit } from "@/methods/huonekortitComposables"
import { hankePage } from "@/views/hanke"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHuonekorttiTableWithFilterAndPagination',
  props: {
    status: {
        type: Array as PropType<Array<HuonekorttiStatusResponse>>,
        default: () => {
            return [
                HuonekorttiStatusResponse.Julkaistu,
                HuonekorttiStatusResponse.Odottaa,
                HuonekorttiStatusResponse.Hyvaksytty,
            ]
        },
    },
    selection: {
        type: Boolean,
        default: false,
    },

},
  setup(__props) {

const props = __props

const { huonekortit, total, filter, currentPage, paramsObject, pageSize, status : _status } = useHuonekortit()
const { selectedVastuuyksikkoIds } = hankePage()

watchEffect(() => paramsObject.value.vastuuyksikot = selectedVastuuyksikkoIds.value)
watchEffect(() => _status.value = props.status)

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode(ComFilterInput, {
              modelValue: _unref(filter),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(filter) ? (filter).value = $event : null))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 18 }, {
          default: _withCtx(() => [
            _createVNode(ComPagination, {
              total: _unref(total),
              "page-size": _unref(pageSize),
              "onUpdate:pageSize": _cache[1] || (_cache[1] = ($event: any) => (_isRef(pageSize) ? (pageSize).value = $event : null)),
              "current-page": _unref(currentPage),
              "onUpdate:currentPage": _cache[2] || (_cache[2] = ($event: any) => (_isRef(currentPage) ? (currentPage).value = $event : null)),
              style: {"float":"right"}
            }, null, 8, ["total", "page-size", "current-page"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(ComHuonekorttiTable, {
      selection: true,
      huonekortit: _unref(huonekortit)
    }, null, 8, ["huonekortit"])
  ], 64))
}
}

})