import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, isRef as _isRef } from "vue"

import ComPageHeader from "../components/layout/ComPageHeader.vue"
import ComHuonekortit from "@/components/hanke/huonekortit/ComHuonekortit.vue"
import ComHanketiedot from "@/components/huonekortti/hanketiedot/ComHanketiedot.vue"
import ComTilat from "@/components/hanke/tilat/ComTilat.vue"
import ComRaportointi from "@/components/hanke/raportointi/ComRaportointi.vue"
import ComOikeudet from "@/components/hanke/oikeudet/ComOikeudet.vue"
import ComTuoHuonekortteja from "@/components/hanke/tuonti/ComTuoHuonekortteja.vue"
import ComRoskakori from "@/components/hanke/roskakori/ComRoskakori.vue"
import ComMallihuonekortit from "@/components/mallihuonekortit/ComMallihuonekortit.vue"
import ComCard from "@/components/common/ComCard.vue"
import { hankePage } from "./hanke"
import ComHaku from "@/components/hanke/haku/ComHaku.vue"


const __default__ = {
    name: "HankeView",
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const { id, selectedVastuuyksikkoIds, selectedVastuuyksikot, selectedTab, hanke, hankeVastuuyksikot, paakayttaja, loading, selectAllVastuuyksikot } = hankePage()


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(hanke))
      ? (_openBlock(), _createBlock(ComPageHeader, {
          key: 0,
          contentTitle: _unref(hanke).nimi
        }, null, 8, ["contentTitle"]))
      : _createCommentVNode("", true),
    _createVNode(ComCard, { loading: _unref(loading) }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "Vastuuyksikkö" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _unref(selectedVastuuyksikkoIds),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedVastuuyksikkoIds) ? (selectedVastuuyksikkoIds).value = $event : null)),
                  multiple: "",
                  style: {"width":"100%"},
                  placeholder: "Valitse"
                }, {
                  header: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      size: "small",
                      onClick: _unref(selectAllVastuuyksikot)
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Valitse kaikki")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(hankeVastuuyksikot), (vastuuyksikko) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: vastuuyksikko.id,
                        label: vastuuyksikko.nimi,
                        value: vastuuyksikko.id
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_tabs, {
          class: "hanke_tab_menu",
          modelValue: _unref(selectedTab),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(selectedTab) ? (selectedTab).value = $event : null))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, {
              label: "Huonekortit",
              name: 0,
              lazy: ""
            }, {
              default: _withCtx(() => [
                _createVNode(ComHuonekortit)
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, {
              label: "Tilat",
              name: 1,
              lazy: ""
            }, {
              default: _withCtx(() => [
                _createVNode(ComTilat)
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, {
              label: "Haku",
              name: 6
            }, {
              default: _withCtx(() => [
                _createVNode(ComHaku)
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, {
              label: "Tiedot",
              name: 2,
              lazy: ""
            }, {
              default: _withCtx(() => [
                _createVNode(ComHanketiedot, {
                  editable: _unref(paakayttaja) || false,
                  hankeId: _unref(id),
                  vastuuyksikot: _unref(selectedVastuuyksikot)
                }, null, 8, ["editable", "hankeId", "vastuuyksikot"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, {
              label: "Raportointi",
              name: 3,
              lazy: ""
            }, {
              default: _withCtx(() => [
                _createVNode(ComRaportointi)
              ]),
              _: 1
            }),
            (_unref(paakayttaja))
              ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                  key: 0,
                  label: "Käyttäjät",
                  name: 4,
                  lazy: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(ComOikeudet)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(paakayttaja))
              ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                  key: 1,
                  label: "Mallihuonekortit",
                  name: 5,
                  lazy: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(ComMallihuonekortit)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(paakayttaja) && _unref(selectedTab) == 0)
              ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                  key: 2,
                  disabled: "",
                  name: "tuo"
                }, {
                  label: _withCtx(() => [
                    _createVNode(ComTuoHuonekortteja)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(paakayttaja) && _unref(selectedTab) == 0)
              ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                  key: 3,
                  disabled: "",
                  name: "roskakori"
                }, {
                  label: _withCtx(() => [
                    _createVNode(ComRoskakori)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["loading"])
  ]))
}
}

})