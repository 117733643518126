<template>
    <div>
        <ComPageHeader v-if="hanke" :contentTitle="hanke.nimi" />
        <ComCard :loading="loading">
            <el-form>
                <el-form-item label="Vastuuyksikkö">
                    <el-select
                        v-model="selectedVastuuyksikkoIds"
                        multiple
                        style="width: 100%"
                        placeholder="Valitse">
                        <template #header>
                            <el-button size="small" @click="selectAllVastuuyksikot">Valitse kaikki</el-button>
                        </template>
                        <el-option
                            v-for="vastuuyksikko in hankeVastuuyksikot"
                            :key="vastuuyksikko.id"
                            :label="vastuuyksikko.nimi"
                            :value="vastuuyksikko.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-tabs class="hanke_tab_menu" v-model="selectedTab">
                <template #default>
                    <el-tab-pane label="Huonekortit" :name="0" lazy>
                        <ComHuonekortit />
                    </el-tab-pane>
                    <el-tab-pane label="Tilat" :name="1" lazy>
                        <ComTilat />
                    </el-tab-pane>
                    <el-tab-pane label="Haku" :name="6">
                        <ComHaku/>
                    </el-tab-pane>
                    
                    <el-tab-pane label="Tiedot" :name="2" lazy>
                        <ComHanketiedot :editable="paakayttaja || false" :hankeId="id" :vastuuyksikot="selectedVastuuyksikot" />
                    </el-tab-pane>
                    <el-tab-pane label="Raportointi" :name="3" lazy>
                        <ComRaportointi />
                    </el-tab-pane>
                    <el-tab-pane label="Käyttäjät" :name="4" lazy v-if="paakayttaja">
                        <ComOikeudet />
                    </el-tab-pane>
                    <el-tab-pane label="Mallihuonekortit" :name="5" lazy v-if="paakayttaja">
                        <ComMallihuonekortit />
                    </el-tab-pane>
                    <el-tab-pane disabled name="tuo" v-if="paakayttaja && selectedTab == 0">
                        <template #label>
                            <ComTuoHuonekortteja />
                        </template>
                    </el-tab-pane>
                    <el-tab-pane disabled name="roskakori" v-if="paakayttaja && selectedTab == 0">
                        <template #label>
                            <ComRoskakori />
                        </template>
                    </el-tab-pane>
                </template>
            </el-tabs>
        </ComCard>
    </div>
</template>

<script lang="ts">
export default {
    name: "HankeView",
}
</script>

<script setup lang="ts">
import ComPageHeader from "../components/layout/ComPageHeader.vue"
import ComHuonekortit from "@/components/hanke/huonekortit/ComHuonekortit.vue"
import ComHanketiedot from "@/components/huonekortti/hanketiedot/ComHanketiedot.vue"
import ComTilat from "@/components/hanke/tilat/ComTilat.vue"
import ComRaportointi from "@/components/hanke/raportointi/ComRaportointi.vue"
import ComOikeudet from "@/components/hanke/oikeudet/ComOikeudet.vue"
import ComTuoHuonekortteja from "@/components/hanke/tuonti/ComTuoHuonekortteja.vue"
import ComRoskakori from "@/components/hanke/roskakori/ComRoskakori.vue"
import ComMallihuonekortit from "@/components/mallihuonekortit/ComMallihuonekortit.vue"
import ComCard from "@/components/common/ComCard.vue"
import { hankePage } from "./hanke"
import ComHaku from "@/components/hanke/haku/ComHaku.vue"

const { id, selectedVastuuyksikkoIds, selectedVastuuyksikot, selectedTab, hanke, hankeVastuuyksikot, paakayttaja, loading, selectAllVastuuyksikot } = hankePage()

</script>
<style scoped>
.hanke_tab_menu :deep(.el-tabs__header) {
    margin-bottom: 40px !important;
}
.hanke_tab_menu :deep(.el-tabs__nav) {
    width: 100%;
}
.hanke_tab_menu :deep(#tab-tuo) {
    order: 3;
    padding-right: 0;
    margin-left: auto;
}
.hanke_tab_menu :deep(#tab-roskakori) {
    order: 4;
}
.hanke_tab_menu :deep(#tab-tuo),
.hanke_tab_menu :deep(#tab-roskakori) {
    display: flex;
    cursor: default;
}
</style>
