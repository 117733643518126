import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

import { useHakuComponent } from './haku';
import { useHuonekorttiFormatTilat } from '@/methods/huonekorttiComposablses';
import ComHakuResultDetails from './ComHakuResultDetails.vue';
import { ref } from 'vue';
import { HuonekorttiResponse } from '@/api/models/huonekortti-response';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHuonekorttiResultTable',
  setup(__props) {

const { huonekortit, markFindCut, getSearchTermValue } = useHakuComponent()
const { formatTilat } = useHuonekorttiFormatTilat()
const huonekorttiDetails = ref<HuonekorttiResponse[]>([])

const setDetails = (row: HuonekorttiResponse, expanded: boolean) => {
    if(expanded) {
        huonekorttiDetails.value.push(row)
    }
    else {
        huonekorttiDetails.value = huonekorttiDetails.value.filter(h => h.id != row.id)
    }
}

const details = (id: number) => {
    return huonekorttiDetails.value.find(h => h.id == id)
}

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _unref(huonekortit),
    border: "",
    onExpandChange: setDetails
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, { type: "expand" }, {
        default: _withCtx((scope) => [
          (details(scope.row.id))
            ? (_openBlock(), _createBlock(ComHakuResultDetails, {
                key: 0,
                huonekortti: details(scope.row.id)
              }, null, 8, ["huonekortti"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "tilanimike.nimi",
        label: "Tilanimike",
        width: "180"
      }, {
        default: _withCtx((scope) => [
          _createVNode(_component_router_link, {
            to: { name: 'Huonekortti', params: { huonekorttiId: scope.row.id } },
            custom: ""
          }, {
            default: _withCtx(({ href, navigate }) => [
              _createVNode(_component_el_link, {
                type: "primary",
                href: href,
                onClick: navigate
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(scope.row.tilanimike.nimi), 1)
                ]),
                _: 2
              }, 1032, ["href", "onClick"])
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Täsmennys",
        "min-width": "250px"
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", {
            innerHTML: _unref(markFindCut)(_unref(getSearchTermValue)('tilanimike-tasmennys'), scope.row.toiminta ?? '', false),
            style: {"margin-left":"10px"}
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "vastuuyksikko.nimi",
        label: "Vastuuyksikkö",
        width: "180"
      }),
      _createVNode(_component_el_table_column, {
        label: "Tilat",
        "min-width": "250"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(_unref(formatTilat)(scope.row.tilat, true)), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}
}

})