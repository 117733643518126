<template>
    <el-table  
        :data="data" 
        @expand-change="setDetails"
        border
        @selection-change="handleSelectionChange">
        <el-table-column v-if="isSelectable" type="selection" width="55" />
        <el-table-column type="expand">
            <template #default="scope">
                <template v-if="details(scope.row.huonekortti.id)">
                    <ComHakuResultDetails :huonekortti="details(scope.row.huonekortti.id)" />
                </template>
            </template>
        </el-table-column>
        <el-table-column label="Nimike" prop="nimike.nimi"/>
        <el-table-column
            prop="tasmennys"
            label="Huomioitavaa"
            min-width="300"
            resizable>
            <template #default="scope">
                <span v-html="markFindCut(getSearchTermValue('tasmennys', scope.row.nimike.id), scope.row.tasmennys, true)" style="margin-left: 10px"></span>
            </template>
        </el-table-column>
        <el-table-column label="Määrä" width="70" prop="maara"/>
        <el-table-column label="Ominaisuudet">
            <template #default="scope">
                <el-tag
                    type="info"
                    size="small"
                    style="margin-right: 2px"
                    v-for="(ominaisuus, index) in scope.row.ominaisuudet"
                    :key="index">
                    <span> {{ ominaisuus.nimi }} </span>
                </el-tag>
            </template>
        </el-table-column>
        <el-table-column label="Tilanimike" prop="huonekortti.tilanimike.nimi">
            <template #default="scope">
                <router-link
                    :to="{ name: 'Huonekortti', params: { huonekorttiId: scope.row.huonekortti.id } }"
                    v-slot="{ href, navigate }"
                    custom>
                    <el-link type="primary" :href="href" @click="navigate">{{ scope.row.huonekortti.tilanimike.nimi }}</el-link>
                </router-link>
            </template>
        </el-table-column>
        <el-table-column
            label="Tilat">
            <template #default="scope">
                {{ formatTilat(scope.row.huonekortti.tilat, true) }}
            </template>
        </el-table-column>
    </el-table>
</template>

<script lang="ts" setup>
import { useNimikeKategoria } from '@/methods/nimikkeetComposables';
import { useHakuComponent } from './haku'
import { ref, watch } from 'vue';
import { HuonekorttiNimikeWithHuonekorttiResponse } from '@/api/models/huonekortti-nimike-with-huonekortti-response';
import { useHuonekorttiFormatTilat } from '@/methods/huonekorttiComposablses';
import ComHakuResultDetails from './ComHakuResultDetails.vue';
import { HuonekorttiResponse } from '@/api/models/huonekortti-response';
import { useNimikeSelection } from './nimikeSelection';

const { nimikkeet, markFindCut, getSearchTermValue } = useHakuComponent()
const data = ref<HuonekorttiNimikeWithHuonekorttiResponse[]>([])
const { nimikeKategoriat } = useNimikeKategoria()
const { formatTilat } = useHuonekorttiFormatTilat()
const { selectedNimikkeet, isSelectable } = useNimikeSelection()
const openedDetails = ref<HuonekorttiResponse[]>([])


const setDetails = (row: HuonekorttiNimikeWithHuonekorttiResponse, expanded: boolean) => {
    if (expanded && row.huonekortti) {
        openedDetails.value?.push(row.huonekortti)
    }
    else {
        openedDetails.value = openedDetails.value?.filter(h => h.id !== row.huonekortti?.id)
    }
}
const details = (id: number) => {
    return openedDetails.value?.find((h: HuonekorttiResponse) => h.id === id) 
}
const handleSelectionChange = (selection: HuonekorttiNimikeWithHuonekorttiResponse[]) => {
    selectedNimikkeet.value = selection
}

watch(nimikeKategoriat, (value) => {
    if(value) {
        data.value = nimikkeet.value
    }
}, { immediate: true })
</script>