import TilaListingParams from "@/types/TilaListingParams";
import http from "@/http-common"
import { Ref, computed, ref, watch } from "vue";
import { TilaResponseExtended } from "@/api/models/tila-response-extended";
import { AxiosResponseHeaders } from "axios";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { TilatSearchRequest } from "@/api/models/tilat-search-request";

export function useTilat(hankeId : Ref<number | undefined>, val? : Ref<TilaListingParams>) {
    const paramsObject = val ?? ref<TilaListingParams>({
        paging: {
            currentPage: 1,
            pageSize: 30
        },
        sort: undefined,
        order: undefined,
        vastuuyksikot: [] as number[],
        tilanimike: "",
        tilanimikeTasmennys: "",
        tilatunnus: "",
    })
    const key = ref<any[]>([])
    const tilat = ref<TilaResponseExtended[]>([])
    const pageTotal = ref(0)
    const pintaAlaTotal = ref(0)
    const enabled = computed(() => hankeId.value !== undefined && paramsObject.value.vastuuyksikot?.length !== 0)

    const getUrlParams = () => {
        let order = ""

        if(paramsObject.value.order) {
            if (paramsObject.value.order.toString() === "ascending") {
                order = "asc"
            } else if (paramsObject.value.order.toString() === "descending") {
                order = "desc"
            }
        }

        const params = Object.assign(
            {},
            paramsObject.value.vastuuyksikot ? { vastuuyksikot: paramsObject.value.vastuuyksikot.join(",") } : null,
            paramsObject.value.paging?.currentPage ? { page: paramsObject.value.paging.currentPage } : null,
            paramsObject.value.paging?.pageSize ? { per_page: paramsObject.value.paging.pageSize } : null,
            paramsObject.value.sort ? { sort: paramsObject.value.sort } : null,
            paramsObject.value.order ? { order: order } : null,
            paramsObject.value.tilanimike ? { tilanimike: paramsObject.value.tilanimike } : null,
            paramsObject.value.tilanimikeTasmennys ? { tilanimike_tasmennys: paramsObject.value.tilanimikeTasmennys } : null,
            paramsObject.value.tilatunnus ? { tilatunnus: paramsObject.value.tilatunnus } : null
        )
    
        return["tilat/hankeId/" + hankeId.value, params]
    }
    const fetcher = async () => {
        const response = await http.get("tilat/hankeId/" + hankeId.value, { params: getUrlParams()[1] })

        return {
            data: response.data as TilaResponseExtended[], 
            headers: response.headers as  AxiosResponseHeaders
        }
    }

    const setAllFilters = (val: string) => {
        paramsObject.value.tilanimike = val
        paramsObject.value.tilanimikeTasmennys = val
        paramsObject.value.tilatunnus = val
    }

    const { data, error, isLoading: loading } = useQuery({
        queryKey: key, 
        queryFn: () => fetcher(), 
        enabled: enabled
    })

    watch(
        paramsObject,
        () => {
            key.value = getUrlParams()
        },
        { deep: true }
    )

    watch(data, () => {
        if (data) {
            tilat.value = data.value?.data ?? []
            pageTotal.value = parseInt(data.value?.headers["page-total-count"])
            pintaAlaTotal.value = Number(data.value?.headers["tila-pintaala-total"])
        }
    }, { deep: true })

    return {
        tilat,
        error,
        loading,
        pageTotal,
        pintaAlaTotal,
        paramsObject,
        setAllFilters
    }
}

export function searchTilat() {
    const tilat = ref<TilaResponseExtended[]>([])
    const total = ref(0)

    const search = (params: TilatSearchRequest) => {
        mutate(params)
    }

    const fetcher = async (request: TilatSearchRequest) => {
        const response = await http.post("tilat/hae", request)

        return {
            data: response.data as TilaResponseExtended[],
            headers: response.headers as AxiosResponseHeaders,
        }
    }

    const { data, error, isPending: loading, mutate } = useMutation({
        mutationFn: (request: TilatSearchRequest) => fetcher(request),
    })

    watch(data, () => {
        if (data) {
            tilat.value = data.value?.data ?? []
            total.value = data.value?.headers["page-total-count"]
                ? parseInt(data.value?.headers["page-total-count"])
                : 0
        }
    })

    return {
        tilat,
        total,
        error,
        loading,
        search,
    }
}