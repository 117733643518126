import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

import { computed, PropType, } from 'vue';
import { useNimike } from '@/methods/nimikkeetComposables';
import { HuonekorttiNimikeSearchParams } from '@/api/models/huonekortti-nimike-search-params';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHakuSuunnittelutiedotOminaisuudet',
  props: /*@__PURE__*/_mergeModels({
    show: { type: Boolean }
  }, {
    "modelValue": { type: Object as PropType<HuonekorttiNimikeSearchParams>, required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {



const nimikeRequest = _useModel(__props, "modelValue")
const id = computed(() => nimikeRequest.value.id)
const { nimike } = useNimike(id)


return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_ctx.show && _unref(nimike))
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("h4", null, _toDisplayString(_unref(nimike).nimi), 1),
        _createVNode(_component_el_form_item, { label: "Ominaisuudet" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: nimikeRequest.value.ominaisuudet,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((nimikeRequest.value.ominaisuudet) = $event)),
              "value-key": "id",
              placeholder: "Valitse haettavat ominaisuudet",
              multiple: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(nimike).ominaisuudet, (ominaisuus) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    label: ominaisuus.nimi,
                    value: ominaisuus.id,
                    key: ominaisuus.id
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(ominaisuus.nimi), 1)
                    ]),
                    _: 2
                  }, 1032, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "Täsmennys" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: nimikeRequest.value.tasmennys,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((nimikeRequest.value.tasmennys) = $event)),
              placeholder: "Hae täsmennyksellä"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ], 64))
    : _createCommentVNode("", true)
}
}

})