<template>
    <ComNimikeMuokkausNimike />
    <el-divider border-style="none"/>
    <ComNimikeMuokkausTable />
</template>

<script setup lang="ts">
import { HuonekorttiNimikeResponse } from '@/api/models/huonekortti-nimike-response';
import { onUnmounted, PropType } from 'vue';
import { useNimikeMuokkausComponent } from './nimikeMuokkaus';
import ComNimikeMuokkausTable from './ComNimikeMuokkausTable.vue';
import _ from 'lodash';
import ComNimikeMuokkausNimike from './ComNimikeMuokkausNimike.vue';

const props = defineProps({
    nimikeet: {
        type: Object as PropType<HuonekorttiNimikeResponse[]>,
        required: true,
    }
})

const { nimikkeet: _nimikkeet, resetState } = useNimikeMuokkausComponent() 

_nimikkeet.value = _.cloneDeep(props.nimikeet)

onUnmounted(() => {
    resetState()
})

</script>