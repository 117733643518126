import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card-margin-bottom" }

import { computed, ref, watch } from "vue"
import { HuonekorttiNimikeRequest } from "@/api/models/huonekortti-nimike-request"
import { useNimikeAdd } from "@/methods/huonekorttiComposablses"
import { NimikeResponse } from "@/api/models/nimike-response"
import { OminaisuusResponse } from "@/api/models/ominaisuus-response"
import NimikeForm from "@/types/NimikeForm"
import { huonekorttiPage } from "@/views/huonekortti"
import { ElMessage, ElNotification } from "element-plus"
import { setNodes, filter } from "@/methods/nimikkeetComposables"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComLisaaNimike',
  setup(__props) {

const { addNimike, loading, error, response } = useNimikeAdd()
const { nimikeKategoriat, huonekortti, setEdited } = huonekorttiPage()
const valittuKategoria = computed(() => nimikeKategoriat.value?.find((k) => k.id === nimikeForm.value.nimike.id?.[0]))
const valittuAlakategoria = computed(() =>
    valittuKategoria.value?.children?.find((a) => a.id === nimikeForm.value.nimike.id?.[1])
)
const valittuNimike = computed(
    () =>
        valittuAlakategoria.value?.nimikkeet?.find((n) => n.id === nimikeForm.value.nimike.id?.[2]) as
            | NimikeResponse
            | undefined
)
const ominaisuudet = computed(() => valittuNimike.value?.ominaisuudet?.filter((o) => o.kaytossa === true) ?? [])
const ominaisuudetChecked = ref<OminaisuusResponse[]>([])
const nimikeFormRef = ref()
const nimikeForm = ref<NimikeForm>({
    nimike: {
        id: undefined,
    },
    maara: undefined,
    tasmennys: "",
})
const rules = ref({
    nimike: [
        {
            type: "object",
            required: true,
            trigger: "blur",
            fields: {
                id: {
                    type: "array",
                    length: 3,
                    required: true,
                    message: "Nimike tulee olla valittu.",
                },
            },
        },
    ],
    maara: [
        {
            type: "integer",
            trigger: "blur",
            required: true,
            min: 0,
            message: "Kappalemäärä tulee olla 0 tai enemmän.",
        },
    ],
})
const nodes = computed(() => {
    return setNodes(nimikeKategoriat.value ?? [])
})
const ohje = computed(() => {
    let message = ""

    const o = [
        {ohje: valittuKategoria.value?.ohje, nimi: valittuKategoria.value?.nimi},
        {ohje: valittuAlakategoria.value?.ohje, nimi: valittuAlakategoria.value?.nimi},
        {ohje: valittuNimike.value?.ohje, nimi: valittuNimike.value?.nimi},
        ...ominaisuudetChecked.value.map((i) => {
            return {ohje: i.ohje, nimi: i.nimi}
        })
    ].filter((o) => o.ohje)

    if (o.length !== 0) {
        message = "<ul>"
        message = message + o.map((m) => `<li>${m.ohje} (${m.nimi})</li>`).join("")
        message = message + "</ul>"

        return message
    } else {
        return false
    }
})
const notification = ref()

const add = async () => {
    nimikeFormRef.value.validate(async (valid: boolean) => {
        if (!valid) { 
            return false
        } else if (huonekortti.value?.id){
            const nimike = {
                nimike: {
                    id: valittuNimike.value?.id,
                },
                maara: nimikeForm.value.maara,
                tasmennys: nimikeForm.value.tasmennys,
                ominaisuudet: ominaisuudetChecked.value.map((o) => {
                    return { id: o.id }
                }),
            } as HuonekorttiNimikeRequest

            addNimike(huonekortti.value?.id, nimike)
        }
    })
}
const resetFields = () => {
    nimikeForm.value.maara = undefined
    nimikeForm.value.tasmennys = ""
    nimikeForm.value.nimike = {
        id: undefined,
    }
    ominaisuudetChecked.value = []
}

watch(loading, (val) => {
    if (!val && response.value) {
        ElMessage.success("Nimike lisätty")

        resetFields()
    }
})
watch(error, (val) => {
    if (val) {
        ElMessage.error("Nimikkeen lisäys epäonnistui")
    }
})
watch(
    () => nimikeForm,
    () => {
        if (nimikeForm.value.maara || nimikeForm.value.nimike.id || nimikeForm.value.tasmennys) {
            setEdited("nimikeLisays", true)
        } else {
            setEdited("nimikeLisays", false)
        }
    },
    { deep: true }
)
watch(ohje, () => {
    if(notification.value) {
        notification.value.close()
    }

    if (ohje.value) {
        notification.value = ElNotification({
            title: "Huomioitavaa",
            message: ohje.value,
            type: "warning",
            duration: 0,
            dangerouslyUseHTMLString: true,
            position: "top-left",
            customClass: "nimike-huomio"
        })
    }
})


return (_ctx: any,_cache: any) => {
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      inline: true,
      "label-position": "top",
      rules: rules.value,
      model: nimikeForm.value,
      ref_key: "nimikeFormRef",
      ref: nimikeFormRef
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          lg: 5,
          span: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "Nimike",
              prop: "nimike"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_cascader, {
                  filterable: "",
                  clearable: "",
                  onChange: _cache[0] || (_cache[0] = ($event: any) => (ominaisuudetChecked.value = [])),
                  options: nodes.value,
                  placeholder: "Hae kirjoittamalla",
                  "filter-method": _unref(filter),
                  size: "small",
                  modelValue: nimikeForm.value.nimike.id,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((nimikeForm.value.nimike.id) = $event)),
                  style: {"width":"100%"}
                }, null, 8, ["options", "filter-method", "modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          lg: 5,
          span: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "Ominaisuudet" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  multiple: "",
                  modelValue: ominaisuudetChecked.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((ominaisuudetChecked).value = $event)),
                  placeholder: "Valitse ominaisuudet",
                  style: {"width":"100%"},
                  size: "small",
                  "value-key": "id"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ominaisuudet.value, (ominaisuus, index) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: index,
                        label: ominaisuus.nimi,
                        value: ominaisuus
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          lg: 3,
          span: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "Kpl",
              prop: "maara"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input_number, {
                  min: 0,
                  size: "small",
                  modelValue: nimikeForm.value.maara,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((nimikeForm.value.maara) = $event)),
                  style: {"width":"100%"}
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          lg: 7,
          span: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "Täsmennys" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: nimikeForm.value.tasmennys,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((nimikeForm.value.tasmennys) = $event)),
                  type: "textarea",
                  autosize: "",
                  style: {"width":"100%"}
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          lg: 4,
          span: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: " " }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "success",
                  onClick: add,
                  size: "small"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Lisää nimike")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["rules", "model"])
  ]))
}
}

})