<template>
    <div style="padding: 0px 20px" v-if="huonekortti">
        <el-row :gutter="20" style="padding-bottom: 10px;">
            <el-col :span="12">
                <h4>Toiminnan kuvaus</h4>
                <div v-html="markFindCut(getSearchTermValue('toiminnan-kuvaus'), huonekortti.toiminnanKuvaus ?? '', true)" style="max-height: 300px; overflow-y: scroll;"></div>
            </el-col>
            <el-col :span="12">
                <h4>Toiminnan vaatimukset</h4>
                <div v-html="markFindCut(getSearchTermValue('toiminnan-vaatimukset'), huonekortti.toiminnanVaatimukset ?? '', true)" style="max-height: 300px; overflow-y: scroll;"></div>
            </el-col>
        </el-row>
        <el-descriptions border :column="4">
            <el-descriptions-item label="Tilanimike">{{ huonekortti.tilanimike?.nimi }}</el-descriptions-item>
            <el-descriptions-item label="Täsmennys"><span v-html="markFindCut(getSearchTermValue('tilanimike-tasmennys'), huonekortti.toiminta ?? '', false)"></span></el-descriptions-item>
            <el-descriptions-item label="Vastuuyksikkö">{{ huonekortti.vastuuyksikko.nimi }}</el-descriptions-item>
            <el-descriptions-item label="Tilat">{{ formatTilat(huonekortti.tilat ?? [], true) }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions border :column="4">
            <el-descriptions-item label="Henkilömäärä normaalisti">{{ huonekortti.henkilomaaraNorm }}</el-descriptions-item>
            <el-descriptions-item label="Henkilömäärä maksimi">{{ huonekortti.henkilomaaraMax }}</el-descriptions-item>
            <el-descriptions-item label="Sähköluokka">{{ huonekortti.sahkoluokka }}</el-descriptions-item>
            <el-descriptions-item label="Toiminta-aika">{{ huonekortti.toimintaAika }}</el-descriptions-item>
        </el-descriptions>
        <ComHakuResultDetailsNimikeTable v-if="nimikkeet && huonekortti.nimikkeet" :nimikeet="huonekortti.nimikkeet" />
    </div>
</template>

<script lang="ts" setup>
import { useHuonekorttiFormatTilat } from '@/methods/huonekorttiComposablses';
import { useHakuComponent } from './haku';
import { HuonekorttiResponse } from '@/api/models/huonekortti-response';
import { computed, ref, watch } from 'vue';
import ComHakuResultDetailsNimikeTable from './ComHakuResultDetailsNimikeTable.vue';

const props = defineProps<{
    huonekortti: HuonekorttiResponse | undefined
}>()

const { markFindCut, getSearchTermValue, searchForm } = useHakuComponent()
const { formatTilat } = useHuonekorttiFormatTilat()
const _huonekortti = ref<HuonekorttiResponse | undefined>()
const nimikkeet = computed(() => props.huonekortti?.nimikkeet != undefined && searchForm.value.nimike?.length > 0)

watch(() => props.huonekortti, (newValue) => {
    _huonekortti.value = newValue
})

</script>