import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

import { VastuuyksikkoResponse } from "@/api/models/vastuuyksikko-response"
import { ref, watch } from "vue"
import { CaretRight, CaretBottom } from "@element-plus/icons-vue"
import ComSideMenuHuonekortit from "./ComSideMenuHuonekortit.vue"
import type { ElScrollbar } from "element-plus"

interface ScrollValues {
    scrollTop: number
    scrollLeft: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ComSideMenuVastuuyksikko',
  props: {
    data: {},
    checkbox: { type: Boolean }
  },
  setup(__props: any) {


const vastuuyksikko = ref(false)
const opened = ref(false)
const pages = ref(1)
const total = ref<number | null>(null)
const perPage = ref(30)
const filterInput = ref("")
const filter = ref("")
const awaitFilter = ref(false)
const scrollbarRef = ref<InstanceType<typeof ElScrollbar>>()

watch(filterInput, () => {
    pages.value = 1
    scrollbarRef.value?.setScrollTop(0)

    if (!awaitFilter.value) {
        setTimeout(() => {
            filter.value = filterInput.value
            awaitFilter.value = false
        }, 1000)
    }
    awaitFilter.value = true
})

const loadHuonekortit = () => {
    vastuuyksikko.value = true
    opened.value = !opened.value
}

const scroll = ({ scrollTop }: ScrollValues) => {
    let p = 0

    if (total.value) {
        p = Math.ceil(total.value / perPage.value)
    }

    if (scrollTop > 220 * pages.value && pages.value < p) {
        pages.value++
    }
}

const setTotalCount = (val: number, val2: number) => {
    total.value = val
    pages.value = val2
}


return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_ctx.data)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("span", {
          class: "node_title",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (loadHuonekortit()))
        }, [
          (opened.value)
            ? (_openBlock(), _createBlock(_component_el_icon, {
                key: 0,
                color: "rgb(192, 196, 204)"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(CaretBottom))
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_el_icon, {
                key: 1,
                color: "rgb(192, 196, 204)"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(CaretRight))
                ]),
                _: 1
              })),
          _createTextVNode(" " + _toDisplayString(_ctx.data.nimi), 1)
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "break" }, null, -1)),
        _withDirectives(_createElementVNode("span", null, [
          _createVNode(_component_el_input, {
            placeholder: "Suodata",
            size: "small",
            modelValue: filterInput.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filterInput).value = $event)),
            modelModifiers: { lazy: true }
          }, null, 8, ["modelValue"]),
          _createVNode(_component_el_scrollbar, {
            "max-height": "400px",
            onScroll: scroll,
            ref_key: "scrollbarRef",
            ref: scrollbarRef,
            class: "side-menu-scroll"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pages.value, (page) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: page,
                  class: "el-tree-node__children huonekortit_nodes"
                }, [
                  (vastuuyksikko.value)
                    ? (_openBlock(), _createBlock(ComSideMenuHuonekortit, {
                        key: 0,
                        id: _ctx.data.id,
                        page: page,
                        filter: filter.value,
                        checkbox: _ctx.checkbox,
                        onTotalCount: setTotalCount
                      }, null, 8, ["id", "page", "filter", "checkbox"]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _: 1
          }, 512)
        ], 512), [
          [_vShow, opened.value]
        ])
      ], 64))
    : _createCommentVNode("", true)
}
}

})